import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../Redux';
import {
    Route,
    RouteProps,
    Redirect
} from 'react-router-dom';

interface PrivateRoutePropsType extends RouteProps { }

function PrivateRoute({
    children,
    ...prs
}: PrivateRoutePropsType) {
    const main_state = useSelector((state: RootStateType) => state.main)

    return (
        <Route
            {...prs}
            render={
                ({ location }) =>
                    main_state.initialized && (
                        main_state.user.is_registered
                            ? (
                                children
                            )
                            : (
                                <Redirect
                                    to={{
                                        pathname: '/',
                                        state: { from: location }
                                    }}
                                />
                            )
                    )
            }
        />
    );
}

export default PrivateRoute;
