import React from 'react';

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M11.5 21.5625C8.83126 21.5625 6.27182 20.5023 4.38474 18.6153C2.49765 16.7282 1.4375 14.1687 1.4375 11.5C1.4375 8.83126 2.49765 6.27182 4.38474 4.38474C6.27182 2.49765 8.83126 1.4375 11.5 1.4375C14.1687 1.4375 16.7282 2.49765 18.6153 4.38474C20.5023 6.27182 21.5625 8.83126 21.5625 11.5C21.5625 14.1687 20.5023 16.7282 18.6153 18.6153C16.7282 20.5023 14.1687 21.5625 11.5 21.5625ZM11.5 23C14.55 23 17.4751 21.7884 19.6317 19.6317C21.7884 17.4751 23 14.55 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0C8.45001 0 5.52494 1.2116 3.36827 3.36827C1.2116 5.52494 0 8.45001 0 11.5C0 14.55 1.2116 17.4751 3.36827 19.6317C5.52494 21.7884 8.45001 23 11.5 23Z" fill="#282A35" />
            <path d="M10.6868 10.6868C10.9025 10.4712 11.195 10.35 11.5 10.35C11.805 10.35 12.0975 10.4712 12.3132 10.6868C12.5288 10.9025 12.65 11.195 12.65 11.5V16.1C12.65 16.405 12.5288 16.6975 12.3132 16.9132C12.0975 17.1288 11.805 17.25 11.5 17.25C11.195 17.25 10.9025 17.1288 10.6868 16.9132C10.4712 16.6975 10.35 16.405 10.35 16.1V11.5C10.35 11.195 10.4712 10.9025 10.6868 10.6868Z" fill="#282A35" />
            <path d="M11.063 5.842C11.343 5.72698 11.657 5.72698 11.937 5.842C12.0782 5.89674 12.2071 5.97881 12.3165 6.0835C12.5266 6.30388 12.6458 6.59552 12.65 6.9C12.6491 7.08835 12.6019 7.27359 12.5126 7.43944C12.4233 7.60528 12.2946 7.74665 12.1379 7.85113C11.9812 7.95561 11.8012 8.02 11.6138 8.03863C11.4264 8.05727 11.2372 8.02958 11.063 7.958C10.9236 7.89977 10.7953 7.81808 10.6835 7.7165C10.5769 7.60905 10.4926 7.48161 10.4354 7.3415C10.3781 7.20138 10.3491 7.05135 10.35 6.9C10.3461 6.74915 10.3776 6.59948 10.442 6.463C10.5002 6.32364 10.5819 6.19528 10.6835 6.0835C10.7929 5.97881 10.9218 5.89674 11.063 5.842Z" fill="#282A35" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="23" height="23" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
