import { Alert } from 'react-bootstrap';

interface FatalErrorProps {
    message: string
}

const FatalError = (props: FatalErrorProps) => {
    return (
        <Alert variant="danger">
            <Alert.Heading>
                Fatal error
            </Alert.Heading>
            <p>
                {props.message}
            </p>
        </Alert>
    )
}
export default FatalError