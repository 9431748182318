import { Dispatch } from 'redux';
import { RootStateType } from '..';
import { setAiContent } from '../Reducer/MainReducer';
import { PricesStatus } from 'src/Util/InterfaceAndTypeUtil';
import { fetchKaiPrices } from 'src/Component/CodeEditor/Panels/AIPanel/Util/fetch';

export function fetchAiPrices() {
  return async (dispatch: Dispatch, getState: () => RootStateType) => {
    const state = getState();
    const pricesState = { ...state.main.ai_content.prices };

    pricesState.status = PricesStatus.LOADING;

    dispatch(setAiContent({ prices: pricesState }));
    try {
      const response = await fetchKaiPrices();

      if (response.error.code === '0') {
        const newPrices = (response.data as any).prices || {};
        pricesState.value = newPrices;
        pricesState.status = PricesStatus.DONE;
      } else {
        pricesState.status = PricesStatus.ERROR;
      }
    } catch (error: any) {
      pricesState.status = PricesStatus.ERROR;
    } finally {
      dispatch(setAiContent({ prices: pricesState }));
    }
  };
}
