
export const createCallbackSelfExecutedWithDelay = (callback: any, delay?: number) => { // unused yet
  /*
    Origin/Inspiration: https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits#hitcallback
    Main purpose of this function is to return a callback function to be executed in some place .. and if the
    execution will not take place there for some reason/error -> still execute it with a delay
  */

  if (typeof delay === 'undefined') {
    delay = 700; // defaults to 700 milliseconds
  }

  let called = false;

  function callbackWrapper() {
    if (!called) {
      called = true;
      callback();
    }
  }

  setTimeout(callbackWrapper, delay);

  return callbackWrapper;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/*
 * WARNING! Makes the whole cpu busy & holds other logic execution. To be used in dev envs only
 */
export const _developmentBusySleep = (ms: number) => {
  const futureUtus = new Date().getTime() + ms;

  while (new Date().getTime() < futureUtus) {
    // noop
  }

  return;
};

