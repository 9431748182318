import React, { createRef, ForwardedRef, useEffect, useMemo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { IDefault } from 'src/Util/InterfaceAndTypeUtil';
import styles from './Tooltip.module.scss';

const UpdatingPopover = React.forwardRef(({ disabled, popper, children, show: _, ...props }: any, ref: ForwardedRef<HTMLDivElement>) => {
  useEffect(() => {
    // console.log('updating tooltip!');
    popper.scheduleUpdate();
  }, [children, popper]);

  const popover = useMemo(() => {
    return (
      <Popover hidden={disabled} ref={ref} content {...props} className={styles.tooltip}>
        {children}
      </Popover>
    );
  }, [children, disabled, props, ref]);

  return popover;
});

interface ITooltip extends IDefault {
  text: React.ReactElement | React.ReactNode | JSX.Element | string;
  children: React.ReactElement | string;
  trigger?: 'hover' | 'click' | 'focus' | Array<'hover' | 'click' | 'focus'>;
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  disabled?: boolean;
  fileName?: boolean;
  delay?: number;
}

const Tooltip = ({
  delay = 0,
  fileName = false,
  placement = 'auto',
  trigger = ['hover', 'focus'],
  children,
  text,
  id,
  className,
  disabled,
}: ITooltip) => {
  const useRef = createRef<HTMLDivElement>();
  const tooltipText = useMemo(() => {
    let returnValue = text;

    if (fileName && typeof text === 'string') {
      // displaying a tooltip of a long filename
      let result = '';
      while ((returnValue as string).toString().length > 0) {
        result += (returnValue as string).toString().substring(0, 38) + '\n';
        returnValue = (returnValue as string).toString().substring(38);
      }

      returnValue = result;
    }

    return returnValue;
  }, [fileName, text]);

  const overlay = useMemo(() => {
    return (
      <UpdatingPopover disabled={disabled} ref={useRef} id='tooltip-popover-contained'>
        {tooltipText}
      </UpdatingPopover>
    );
  }, [disabled, tooltipText, useRef]);

  // wrap the children in a span since not all children can accept a ref being placed on them.
  return (
    <OverlayTrigger placement={placement} trigger={trigger} delay={delay} transition={false} overlay={overlay}>
      {({ ref, ...triggerHandler }) => (
        <span className='d-inline-block w3s-tooltip-wrapper' {...triggerHandler} ref={ref}>
          {children}
        </span>
      )}
    </OverlayTrigger>
  );
};

export default Tooltip;
