// https://startup-cto.net/10-bad-typescript-habits-to-break-this-year/ TODO: (low) check this article lefy by Julian

import { AxiosResponse } from 'axios';
import { KaiActionPrices } from 'src/Component/CodeEditor/Panels/AIPanel/Util/kai_types';

// Optional declares a type of keyed type T where all keys are optional
export type Optional<T> = { [P in keyof T]?: T[P] }; // DEPRECATED! Use OptionalTypeMod instead
export type OptionalTypeMod<T> = { [P in keyof T]?: T[P] };

export type CustomTypeMod<T, R> = Omit<T, keyof R> & R;

export type FlippedTypeMod<T> = {
  [K in keyof T as `${string & T[K]}`]: K;
};

export type GetEnumValues<T> = keyof FlippedTypeMod<T>;

export type StrKeyToBooleanMapType = {
  [key: string]: boolean;
};

export type StrKeyToStrMapType = {
  [key: string]: string;
};

export type StrKeyToNumMapType = {
  [key: string]: number;
};

export interface OperationResultType {
  error: ErrorType;
  data: any;
}

export interface FileProcessingResponseType {
  data: {
    file: DiskFileType;
    _update_main_state?: OptionalMainStateType;
    _update_space?: UpdateSpaceType;
  };
  error?: ErrorType;
}

export interface DiskFileType {
  Key: string;
  LastModified: string;
  Size: number;
}

export interface RawFileType extends File {}

export interface BackendFileType {
  space_id: string;
  path: string; // absolute - including space_id
  rel_path: string; // relative to the space_id
  dirs: string[];
  base_name: string;
  base_name_lowercased?: string;
  name: string;
  extension: string;
  mime_type: string;
  is_text_based: boolean;
  size: number; // in bytes
  last_modified?: Date;
  pending_upload_uts?: number;
}

export type BackendFileMapType = {
  [rel_path: string]: BackendFileType;
};

export interface FileType extends BackendFileType {
  contents?: string;
  raw_meta?: RawFileType;
  processing?: boolean;
  processing_status?: FileProcessingStatuses;
  processing_status_uts?: number;
  processing_status_error?: string | JSX.Element | React.ReactNode | undefined;
  deletable_while_processing?: string; // concat of pending_upload_uts and processing_status_uts
  is_folder?: boolean;
}

/**
 * Interface for folders used when moving
 */
export interface TreeItemType extends FileType {
  expanded: boolean;
  level: number;
  numberOfChildren: number;
  parent?: string;
}

export interface ITreeItem {
  /**
   * Name of item
   */
  name: string;
  /**
   * Path to item
   */
  path: string;
  /**
   * Is item a folder or space item
   */
  isFolder: boolean;
  /**
   * Is this the root item, the space
   */
  isSpace: boolean;
  /**
   * Parent path
   */
  parent: string;
  /**
   * All children to this item
   */
  children: ITreeItem[];
  /**
   * Is the children expanded
   */
  expanded: boolean;
  /**
   * Is this text based
   */
  isTextBased: boolean;
  /**
   * Mark item as a new item
   */
  markAsNew?: boolean;
  /**
   * The full path to the file. This has to be set on non-text-based and non-folder items so that their url can be copied
   */
  fullPathURL?: string;
  /**
   * The extension of the item, e.g. html, pdf, css
   */
  extension?: string;
  /**
   * Mime type of the file
   */
  mimeType?: string;
  /**
   * Indicates if an item is open in the editor in a tab
   */
  isOpen?: boolean;
  /**
   * Indicates if a folder is locked cause an item is open bellow it
   */
  isLocked?: boolean;
}

export type FileMapType = {
  [rel_path: string]: FileType;
};

export interface ErrorType {
  code: string;
  description?: string;
  context?: string;
  list?: any;
  meta?: any;
}

export interface FunctionWithErrorHandlingReturnPropsType {
  error: ErrorType;
}

export interface ModalPropsType {
  show: boolean;
  close: () => void;
}

export interface FileModalPropsType extends ModalPropsType {
  fetchFiles?: () => void;
  file?: FileType;
  files?: FileType[];
  spaceId: string;
}

export interface ApiResponseType {
  error?: ErrorType;
  errors?: ErrorType[];
  data?: any;
}

export function typedAction<T extends string>(type: T): { type: T };

export function typedAction<T extends string, P extends any>(type: T, payload: P): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export type UserNotificationType = {
  id: string;
  dismissed: boolean;
  type: string;
  header: string;
  body?: string | JSX.Element;
  filename?: string;
  autohide?: boolean;
  delay?: number;
  closeButton?: boolean;
  showProgressBar?: boolean;
  progressBarLevel?: number;
  /**
   * If true, the notif will not be removed when Close All is clicked
   */
  pinned?: boolean;
  onClose?: (id: string) => void;
};

export type UserNotificationDismissType = Optional<UserNotificationType> & {
  id: string;
};

export type UserNotificationUpdateType = Optional<UserNotificationType> & {
  id: string;
};

export type ModalType = 'error';
export interface ModalDataType {
  id: string;
  dismissed: boolean;
  type: ModalType;
  disabled?: boolean;
  loading?: boolean;
  body: string | JSX.Element | React.ReactNode;
  header: string | JSX.Element | React.ReactNode;
  learnMoreButtonText?: string;
  upgradeButtonText?: string;
  dismissButtonText?: string;
}

export type ModalDismissType = Optional<ModalDataType> & {
  id: string;
};

export type ModalUpdateType = Optional<ModalDataType> & {
  id: string;
};
export interface PresignedUrlFieldsType {
  key: string;
  AWSAccessKeyId: string;
  'x-amz-security-token': string;
  policy: string;
  signature: string;
}

export interface PresignedUrlType {
  url: string;
  fields: PresignedUrlFieldsType;
  filePath: string;
}

export interface PresignedUrlResponseType {
  data: {
    url: PresignedUrlType;
    _update_main_state?: OptionalMainStateType;
    _update_space?: UpdateSpaceType;
  };
  error?: ErrorType;
}

export interface IFilesForUploadMap {
  [fileRelPath: string]: {
    size: number;
    overwrite?: boolean;
  };
}

export interface IPresignedUrlsMap {
  [fileRelPath: string]: PresignedUrlType;
}

export interface IRegisterFilesForUploadRes {
  data: {
    uploadGroupId: string;
    presignedUrls: IPresignedUrlsMap;
    _update_main_state?: OptionalMainStateType;
    _update_space?: UpdateSpaceType;
  };
  error?: ErrorType;
}

export interface ICheckUploadFileStatusRes {
  error: ErrorType;
  data: {
    filesWithError: { [fileRelPath: string]: ErrorType };
    filesPendingUpload: string[];
    filesUploaded: string[];
  };
}

export interface SpaceTemplateType {
  template_id: string;
  title: string;
  subTitle?: string; // used with blank templates
  thumbnail?: string;
  tech: SpaceVariant;
  tag: TemplateTechnologyType;
  description?: string;
  collection_id?: string;
  path: string; // relative path in backend
}

export interface RequestResponseType extends Optional<AxiosResponse> {
  data: any;
  error: ErrorType;
}

export interface LandingPageFeatureType {
  title: string;
  subtitle: string;
}

export interface ContactDetailsType {
  // keep in sync with the backend clone
  addressLine1: string;
  addressLine2?: string;
  city: string;
  contactType: string;
  countryCode: string;
  // email: string,
  fax?: string;
  firstName: string;
  lastName: string;
  organizationName?: string;
  phoneCountryCode: string;
  phoneNumber: string;
  state?: string;
  zipCode: string;
}

export interface DomainOrderStatusType extends StrKeyToBooleanMapType {
  // keep in sync with the backend clone
  ERROR: boolean;
  ORDER_CREATED: boolean;
  ORDER_RESTARTED: boolean;
  PAYMENT_REGISTERED: boolean;
  ORDER_PROCESSING_INITIALIZED: boolean;
  DOMAIN_REGISTRATION_INITIALIZED: boolean;
  PAYMENT_COMPLETED: boolean;
  PAYMENT_CANCELLED: boolean;
  PAYMENT_CANCELLATION_FAILED: boolean;
  DOMAIN_REGISTRATION_COMPLETED: boolean;
  ORDER_PROCESSING_COMPLETED: boolean;
  DOMAIN_CANCELLED: boolean;
  DOMAIN_LOST: boolean;
  RENEWAL_EXPIRED: boolean;
  RENEWAL_ATTEMPT_FAILED: boolean;
  UPCOMING_RENEWAL_NOTIFICATION_SENT: boolean;
  DOMAIN_CLEANUP_INITIALIZED: boolean;
  DOMAIN_CLEANUP_COMPLETED: boolean;
  DOMAIN_TRANSFERRED_OUT: boolean;
}

export interface DomainOrderStatusHistoryItemType {
  key: string; // status key
  val: boolean; // status value
  uts: number; // unix time stamp
}

export interface DomainOrderType {
  // keep in sync with the backend clone
  userId: string;
  orderId: string;
  spaceId: string;
  status: DomainOrderStatusType;
  statusHistory: DomainOrderStatusHistoryItemType[];
  statusLastEntry: string;
  domainName: string;
  baseName: string;
  tld: string;
  contactDetails: ContactDetailsType;
  // workflowMeta: OrderWorkflowMetaType,
  errors: any[];
  timestamp: number;
  version: number;
  dryRun: boolean;

  domainExpirationUts: number;
  finalRenewalAttemptUts: number;
  initialRenewalAttemptUts: number;
  nextRenewalAttemptUts: number;
  paymentCompletedUts: number;
  autoRenew: boolean;
  active: boolean;
}

export interface DomainProductType {
  name: string;
  price: string;
  currency: string;
}

export type DomainProductMapType = {
  [key: string]: DomainProductType;
};

export type SpaceTabType = {
  tabs: ITabType[];
  activeTab: number;
};

export type EditorContentType = {
  url: string | null;
  file_path: string | null;
  spacesData?: { [key: string]: SpaceTabType };
  domainNameForSpace: string | null;
  useRequestsOnPreview?: boolean;
  backFromEditor?: boolean;
  /**
   * We have loaded files, safe to be in editor -> otherwise go back to files from editor (F5 in browserwhen in editor will trigger this)
   */
  filesAreLoaded: boolean;
  textToInsertEditor?: string;
};

export enum AiFetchEnum {
  NONE = 'None',
  CHAT = 'chat',
  EXPLAIN = 'explain',
  /* SNIPPETS = 'snippet', */
  ERROR = 'errors-fix',
  AUTOCOMPLETE = 'autocomplete',
  AUTOTEST = 'autotest',
  REFACTOR = 'refactor',
}

export enum PricesStatus {
  NOT_YET_REQUESTED = 'NOT_YET_REQUESTED',
  LOADING = 'LOADING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export type IAIPrices = {
  status: PricesStatus;
  value: KaiActionPrices;
};

export interface KaiChatHistory {
  prompt: string; // prompt entered by user
  response: string; // AI response
}

export type AiContentStateType = {
  aiContentArray?: IAiChat[];
  aiSelectedCode?: string;
  type: AiFetchEnum;
  language: string | undefined;
  loading: boolean;
  prices: IAIPrices;
};

export type SpaceContentStateType = {
  loadingFiles: boolean;
  prepareingDynamic: boolean;
  isSpaceAllSetUp: boolean;
};

export enum GitHubIntergrationStatus {
  ACCOUNT_LINKED = 'accountLinked',
  REPO_LINKED = 'repoLinked',
  LOGIN_REQUIRED = 'loginRequired',
}

export type GitHubDataType = {
  userName: string;
  avatarUrl: string;
};

export type KaiTokenType = {
  wallet: number;
  estimatedCost?: number;
};

export type FeatureFlagsType = {
  noUpsell: boolean;
  adFree: boolean;
};

// < improved
export type MainStateType = {
  loading: string[];
  initialized: boolean; // fully_initialized || partially_initialized
  fully_initialized: boolean; // registered user in spaces
  partially_initialized: boolean; // not registered user in spaces
  user: UserInfoType;
  subscription_plan_options: SubscriptionPlanOptionsType;
  subscription_plan_option: SubscriptionPlanOptionItemType; // active option
  subscription_plan: SubscriptionPlanType;
  quota: QuotaType;
  usage: UsageType;
  usage_status: UsageStatusType;
  spaces: SpaceType[];
  fatal_error?: string;
  files_uploaded: FileType[];
  space_path: string | undefined;
  display_footer: boolean;
  display_header: boolean;
  editor_content: EditorContentType;
  space_content_state: SpaceContentStateType;
  ai_content: AiContentStateType;
  github_status?: GitHubIntergrationStatus;
  github_data?: GitHubDataType;
  kaiToken: KaiTokenType;
  featureFlags: FeatureFlagsType;
};

export type OptionalMainStateType = Optional<MainStateType>;

export interface UserInfoType {
  is_registered: boolean;
  onboarding_step: number; // 0 - completed | 1 - NewUserLanding | 2 - redirect to /createspace
  is_resetting_space?: string;
  id: string;
  trial: IUserTrial;
  current_uts: number;
}

/**
 * Default interface for component. Adds common html properties.
 */
export interface IDefault {
  /**
   * Specifies a unique id for an element
   */
  id?: string;
  /**
   * Specifies one or more classnames for an element (refers to a class in a style sheet)
   */
  className?: string;
}

export interface IUserTrial {
  start_uts: number;
  end_uts: number;
  type: 'none' | 'standard' | 'extended';
}

export interface SubscriptionPlanType {
  id: string;
  name: string;
  cname: string; // capitalized name
  frequency: string;
  status: string;
  time_span_start_uts: number;
  time_span_end_uts: number;
  // < provided only in the ui version based on SubscriptionPlanOptionItemType
  is_visible: boolean;
  can_be_upgraded: boolean;
  upgrade_to_id?: string;
  upgrade_to_name?: string;
  upgrade_to_cname?: string;
  can_be_downgraded: boolean;
  downgrade_to_id?: string;
  downgrade_to_name?: string;
  downgrade_to_cname?: string;
  is_active?: boolean;
  // > provided only in the ui version based on SubscriptionPlanOptionItemType
}

export interface QuotaWebTrafficType {
  data_served: number; // in kb
  number_of_requests: number;
  unique_visitors: number;
}

export interface QuotaStorageType {
  amount: number; // in kb
  max_file_size: number; // in kb
  number_of_spaces: number;
  number_of_dynamic_spaces: number;
  number_of_files_per_space: number;
  number_of_folders_per_space: number;
}

export interface QuotaDynamicType {
  number_of_resets: number;
  storage_amount: number; // in kb (same as storage.amount)
}

export interface QuotaType {
  trial: IUserTrial;
  web_traffic: QuotaWebTrafficType;
  storage: QuotaStorageType;
  dynamic: QuotaDynamicType;
}

export type UsageStatusEnumType = 'ok' | 'close_to_quota' | 'out_of_quota';

export interface IUsageStatusTrialType {
  _out_of_quota: boolean;
  time_elapsed: UsageStatusEnumType;
}

export interface UsageStatusWebTrafficType {
  _out_of_quota: boolean;
  data_served: UsageStatusEnumType;
  number_of_requests: UsageStatusEnumType;
  unique_visitors: UsageStatusEnumType;
}

export interface UsageStatusStorageType {
  _out_of_quota: boolean;
  amount: UsageStatusEnumType;
  max_file_size: UsageStatusEnumType;
  number_of_spaces: UsageStatusEnumType;
  number_of_dynamic_spaces: UsageStatusEnumType;
  number_of_files_per_space: UsageStatusEnumType;
  number_of_folders_per_space: UsageStatusEnumType;
}

export interface UsageStatusDynamicType {
  _out_of_quota: boolean;
  number_of_resets: UsageStatusEnumType;
  storage_amount: UsageStatusEnumType;
}

export interface UsageStatusType {
  _out_of_quota: boolean;
  trial: IUsageStatusTrialType;
  web_traffic: UsageStatusWebTrafficType;
  storage: UsageStatusStorageType;
  dynamic: UsageStatusDynamicType;
}

export interface UsageWebTrafficType {
  data_served: number; // in kb
  number_of_requests: number;
  unique_visitors: number;
}

export interface UsageStorageType {
  amount: number; // in kb (sum)
  max_file_size: number; // in kb (biggest one)
  number_of_spaces: number; // (sum)
  number_of_dynamic_spaces: number; // (sum)
  number_of_files_per_space: number; // (biggest one)
  number_of_folders_per_space: number; // (biggest one)
}

export interface UsageDynamicType {
  number_of_resets: number; // (sum)
  storage_amount: number; // in kb (sum)
}

export interface UsageType {
  trial_current_uts: number;
  web_traffic: UsageWebTrafficType;
  storage: UsageStorageType;
  dynamic: UsageDynamicType;
  kai: { tokens: number };
}

type SpaceUsageType = UsageType;

/* export interface FolderType extends BackendFileType{
    text: string
    path: string
    expanded: boolean
    level: number
    numberOfChildren: number
} */

export enum DesiredStatus {}
export enum DynamicStatus {
  'RUNNING',
  'PENDING',
  'PROVISIONING',
  'STOPPED',
  'STOPPING',
  'DEPROVISIONING',
  'CREATED',
}
export interface IDynamicMeta {
  desiredStatus: 'RUNNING' | 'STOPPED' | 'DESTROYED' | 'UNKNOWN';
  image: string;
  isPublic: boolean;
  status: keyof typeof DynamicStatus;
  backupRetrievalStatus: 'RUNNING' | 'DONE' | 'ERROR';
  taskDefinitionVersion: number;
}

export enum GitHubSyncStatus {
  PENDING = 'pending',
  DONE = 'done',
  FAILED = 'failed',
}

export interface SpaceType {
  id: string;
  files: FileType[];
  folders: FileType[];
  state: SpaceStateType;
  usage: SpaceUsageType;
  type: 'static' | 'dynamic';
  templateId: string;
  finalize_deletion_uts?: number;
  dynamicMeta?: IDynamicMeta;
  github?: {
    repoName: string;
    commitSha: string;
    branch: string;
    latestSyncDateUts: number;
    syncStatus: GitHubSyncStatus;
    errorMessage?: string;
  };
  dynamicHibernateMode?: 'IDLE';
}

export interface IDynamicSpacetype {
  ownerId: string;
  spaceId: string;
  spaceState: SpaceStateType;
  dynamicTaskArn: string;
  dynamicMeta: IDynamicMeta;
}

export interface UpdateSpaceType {
  id: string;
  files?: FileType[];
  folders?: FileType[];
  state?: SpaceStateType;
  usage?: SpaceUsageType;
  type?: 'static' | 'dynamic';
  templateId?: string;
  finalize_deletion_uts?: number;
  dynamicMeta?: IDynamicMeta;
  github?: {
    repoName: string;
    commitSha: string;
    branch: string;
    latestSyncDateUts: number;
    syncStatus: GitHubSyncStatus;
    errorMessage?: string;
  };
}

export type SpaceStateType = 'OPEN' | 'INACTIVE' | 'DELETED' | 'BANNED' | 'PRIVATE';

export type SubscriptionPlanOptionsType = {
  [plan_name: string]: SubscriptionPlanOptionItemType;
};

export interface SubscriptionPlanOptionItemType {
  slug: string;
  title: string;
  name: string;
  cname: string;
  frequency: 'daily' | 'monthly';
  price: string;
  currency: string;
  description: string;
  unique_selling_propositions_title: string;
  unique_selling_propositions: string;
  quota: QuotaType;
  position: number;
  is_visible: boolean;
  can_be_upgraded: boolean;
  upgrade_to_id?: string;
  upgrade_to_name?: string;
  upgrade_to_cname?: string;
  can_be_downgraded: boolean;
  downgrade_to_id?: string;
  downgrade_to_name?: string;
  downgrade_to_cname?: string;
}

export enum FileProcessingStatuses {
  Active = 'active',
  OnHold = 'on_hold',
  Uploaded = 'uploaded',
  Error = 'error',
  NewFolder = 'new_folder',
}

export type FileProcessingStatusErrorType = string | JSX.Element | React.ReactNode | undefined;
// > improved

export enum OrientationType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  NONE = 'none',
}

export interface ITabType {
  name: string;
  path: string;
  content: string;
  editorContent: string;
  language: string;
  extension: string;
  canBeSaved: boolean;
  timestamp: number;
  loading: boolean;
}

export enum SpaceVariant {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export enum SpaceVariantType {
  HTML = 'html',
  REACT = 'react',
  VUE = 'vue',
  NODE = 'node',
  PHP = 'php',
  PYTHON = 'python',
  HANDLEBARS = 'handlebars',
  SPRINGBOOT = 'springboot',
  LARAVEL = 'laravel',
  JAVA = 'java',
  CSHARP = 'csharp',
  ROCKET = 'rocket',
  RUST = 'rust',
  NONE = 'none',
}

export enum TemplateTechnologyType {
  VANILLA = 'vanilla',
  TEMPLATES = 'templates',
  NODE = 'node',
  PHP = 'php',
  PYTHON = 'python',
  JAVA = 'java',
  CSHARP = 'csharp',
  RUST = 'rust',
}

export interface ITemplateTechnologyType {
  /**
   * Which technology
   */
  tech: TemplateTechnologyType;
  /**
   * A description of the technology
   */
  description: string;
  /**
   * Title of the technology
   */
  title: string;
}

export interface ITemplateCollection {
  /**
   * Id of template collection
   */
  id: string;
  /**
   * Description of the template collection
   */
  templateDescription: string;
  /**
   * Title of the template collection
   */
  templateTitle: string;
}

export interface ISpaceUsageData {
  totalNrOfBaseSpaces: number;
  nrOfBaseSpaces: number;
  totalNrOfDynamicSpaces: number;
  nrOfDynamicSpaces: number;
}

export enum FinishReasonType {
  STOP = 'stop',
  LENGTH = 'length',
  FUNCTION_CALL = 'function_call',
}

export interface IAiResponseType {
  feedback: string;
  codeBlock: boolean;
  cost?: number;
  status: 'loading' | 'ready' | 'error';
  finishReason?: FinishReasonType;
}

export interface IAiQuestionType {
  text: string;
  usePre: boolean;
}

export interface IAiChat {
  id: string;
  question: IAiQuestionType;
  response: IAiResponseType[];
  type: AiFetchEnum;
}

export enum PanelItemTypes {
  NOTHING = 'nothing',
  AI = 'ai',
  Folders = 'folders',
}

export const fileExtensionToLanguageMap: StrKeyToStrMapType = {
  xml: 'xml',
  txt: 'plaintext',
  svg: 'html',
  js: 'javascript',
  json: 'json',
  html: 'html',
  htm: 'html',
  css: 'css',
  md: 'markdown',
  ts: 'typescript',
  tsx: 'typescript',
  jsx: 'javascript',
  ejs: 'html',
  vue: 'plaintext',
  py: 'python',
  php: 'php',
  scss: 'scss',
  mjs: 'javascript',
  hbs: 'handlebars',
  env: 'plaintext',
  babelrc: 'json',
  java: 'java',
  cs: 'csharp',
  gradle: 'plaintext',
  cshtml: 'razor',
  rs: 'rust',
  toml: 'plaintext',
  svelte: 'plaintext',
};

export interface ITime {
  local: string, // Dec 15, 21:08:02 (+2:00)
  gmt: string, // Dec 15, 19:08:02 GMT
};
