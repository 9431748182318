import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { v4 } from 'uuid';
import { Modal as BModal } from 'react-bootstrap';
import { ButtonV2, ButtonV2Type, Spinner } from 'w3-user-ui-component';

import { IDefault } from '../../Util/InterfaceAndTypeUtil';
import BackArrowButton from '../Buttons/BackArrowButton';
import { BREAKPOINTS, isActivationKeyPressed } from '../../Util/MixedUtil';

import './Modal.scss';

export type ButtonElemType = 'a' | 'button';

export interface IProp extends IDefault {
  /**
   * Hide/show the modal
   * @default false
   */
  show: boolean;
  /**
   * Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size.
   * @default undefined
   */
  size?: 'sm' | 'lg' | 'xl' | 'fullscreen' | undefined;
  /**
   * Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked.
   * @default true
   */
  backdrop?: boolean | 'static' | undefined;
  /**
   * True if we are to display a close button, an x in the corner
   * @default true
   */
  closeButton?: boolean;
  /**
   * The header text
   * @default ''
   */
  title?: string | JSX.Element | React.ReactNode;
  /**
   * True if we are to display a Cancel button
   * @default true
   */
  displayCancel?: boolean;
  /**
   * True if we are to display a Ok button
   * @default true
   */
  displayOk?: boolean;
  /**
   * Text of the cancel button
   * @default 'Cancel'
   */
  cancelText?: string;
  /**
   * Text of the ok button
   * @default 'Ok'
   */
  okText?: string;
  /**
   * Variant of the ok button
   * @default 'green'
   */
  okButtonVariant?: ButtonV2Type;
  /**
   * Variant of the cancel button
   * @default 'light'
   */
  cancelButtonVariant?: ButtonV2Type;
  /**
   * The type of the ok button
   * @default 'button'
   */
  okButtonType?: ButtonElemType;
  /**
   * The type of the cancel button
   * @default 'button'
   */
  cancelButtonType?: ButtonElemType;
  /**
   * Extra component to be positioned to the left of the Cancel and Ok button
   */
  preExtraButtons?: JSX.Element;
  /**
   * Extra component to be positioned between the Cancel and Ok button
   */
  extraButtons?: JSX.Element;
  /**
   * Extra component to be positioned to the right of the Cancel and Ok button
   */
  postExtraButtons?: JSX.Element;
  /**
   * True if ok buttons should be disabled
   */
  disabled?: boolean;
  /**
   * True if loading animation on ok button should be displayed. Cancel button will be disabled when True.
   */
  loading?: boolean;
  /**
   * Allows scrolling the <Modal.Body> instead of the entire Modal when overflowing.
   */
  scrollable?: boolean;
  /**
   * Force the module to be full screen on medium sized viewports
   */
  forceFullScreenOnMedium?: boolean;
  /**
   * Handle when the modal is closed
   * @param e Event
   */
  handleClose?: (e?: any) => void;
  /**
   * Handle when the ok button is clicked
   * @param e Event
   */
  handleOk?: (e?: any) => void;
  /**
   * Handle when the modal is displayed
   * @param e Event
   */
  handleShow?: (e?: any) => void;
  disableOk?: boolean;

  headerContent?: JSX.Element[];

  topRowContent?: JSX.Element;
  ariaLabelSpinner?: string;
}

export const defaultValues: React.PropsWithChildren<IProp> = {
  show: false,
  size: undefined,
  backdrop: true,
  closeButton: true,
  title: '',
  displayCancel: true,
  displayOk: true,
  cancelText: 'Cancel',
  okText: 'Ok',
  disableOk: false,
  okButtonVariant: 'green',
  cancelButtonVariant: 'light',
  okButtonType: 'button',
  cancelButtonType: 'button',
  preExtraButtons: undefined,
  extraButtons: undefined,
  postExtraButtons: undefined,
  disabled: false,
  loading: false,
  scrollable: true,
  forceFullScreenOnMedium: false,
  handleClose: undefined,
  handleOk: undefined,
  handleShow: undefined,
  children: undefined,
  ariaLabelSpinner: undefined,
};

function Modal(props: React.PropsWithChildren<IProp>) {
  const [closeButton, setCloseButton] = useState(defaultValues.closeButton);

  const myID = useMemo(() => {
    return v4();
  }, []);

  const [okButtonType, setOkButtonType] = useState<ButtonElemType>(defaultValues.okButtonType!);
  useEffect(() => {
    if (props.okButtonType !== undefined) {
      setOkButtonType(props.okButtonType);
    }
  }, [props.okButtonType]);

  const [cancelButtonType, setCancelButtonType] = useState<ButtonElemType>(defaultValues.cancelButtonType!);
  useEffect(() => {
    if (props.cancelButtonType !== undefined) {
      setCancelButtonType(props.cancelButtonType);
    }
  }, [props.cancelButtonType]);

  const [okVariant, setOkVariant] = useState<ButtonV2Type>(defaultValues.okButtonVariant!);
  useEffect(() => {
    if (props.okButtonVariant !== undefined) {
      setOkVariant(props.okButtonVariant);
    }
  }, [props.okButtonVariant]);

  const [cancelVariant, setCancelVariant] = useState<ButtonV2Type>(defaultValues.cancelButtonVariant!);
  useEffect(() => {
    if (props.cancelButtonVariant !== undefined) {
      setCancelVariant(props.cancelButtonVariant);
    }
  }, [props.cancelButtonVariant]);

  const [backdrop, setBackdrop] = useState<boolean | 'static'>(defaultValues.backdrop!);
  useEffect(() => {
    if (props.backdrop !== undefined) {
      setBackdrop(props.backdrop);
    } else {
      setBackdrop(true);
    }
  }, [props.backdrop]);

  const [fullScreen, setFullScreen] = useState(false);
  const [size, setSize] = useState<'sm' | 'lg' | 'xl' | undefined>();
  useEffect(() => {
    if (props.size !== 'fullscreen') {
      setSize(props.size);
      setFullScreen(false);
    } else {
      setSize(undefined);
      setFullScreen(true);
    }
  }, [props.size]);

  const [title, setTitle] = useState<string | JSX.Element | React.ReactNode>(defaultValues.title);
  useEffect(() => {
    if (props.title !== undefined) {
      setTitle(props.title);
    } else {
      setTitle('');
    }
  }, [props.title]);

  const [cancelText, setCancelText] = useState(defaultValues.cancelText);
  useEffect(() => {
    if (!!props.cancelText) {
      setCancelText(props.cancelText);
    }
  }, [props.cancelText]);

  const [okText, setOkText] = useState(defaultValues.okText);
  useEffect(() => {
    if (!!props.okText) {
      setOkText(props.okText);
    }
  }, [props.okText]);

  const [scrollable, setScrollable] = useState<boolean>(defaultValues.scrollable!);
  useEffect(() => {
    if (props.scrollable !== undefined) {
      setScrollable(!!props.scrollable);
    }
  }, [props.scrollable]);

  const [show, setShow] = useState(defaultValues.show);
  useEffect(() => {
    if (props.show !== undefined) {
      setShow(props.show);
    } else {
      setShow(false);
    }
  }, [props.show]);

  const [displayCancel, setDisplayCancel] = useState(defaultValues.displayCancel);
  useEffect(() => {
    if (props.displayCancel !== undefined) {
      setDisplayCancel(props.displayCancel);
    } else {
      setDisplayCancel(true);
    }
  }, [props.displayCancel]);

  const [displayOk, setDisplayOk] = useState(defaultValues.displayOk);
  useEffect(() => {
    if (props.displayOk !== undefined) {
      setDisplayOk(props.displayOk);
    } else {
      setDisplayOk(true);
    }
  }, [props.displayOk]);

  const classModal = useMemo(() => {
    let returnValue = 'primary';
    if (okVariant === 'red') {
      returnValue = 'destructive';
    }

    return returnValue;
  }, [okVariant]);
  /**
   * Close the modal
   * @param e {Event}
   */
  const closeModal = useCallback(
    (e?: any) => {
      setShow(false);
      if (!!props.handleClose) {
        props.handleClose(e);
      }
    },
    [props],
  );

  /**
   * Click the ok button
   * @param e {Event}
   */
  const onClickOk = useCallback(
    (e?: any) => {
      if (!!props.handleOk && !props.disabled && !props.loading && !props.disableOk) {
        props.handleOk(e);
      }
    },
    [props],
  );

  const checkIfFullScreen = useCallback(() => {
    return window.innerWidth < BREAKPOINTS.xsmall || (props.forceFullScreenOnMedium && window.innerWidth < BREAKPOINTS.small);
  }, [props.forceFullScreenOnMedium]);

  const setCloseButtonValue = useCallback(
    (e?: any) => {
      if (props.closeButton !== undefined) {
        setCloseButton(checkIfFullScreen() ? false : !!props.closeButton);
      } else {
        setCloseButton(checkIfFullScreen() ? false : true);
      }
    },
    [checkIfFullScreen, props.closeButton],
  );

  useEffect(() => {
    setCloseButtonValue();
  }, [props.closeButton, setCloseButtonValue]);

  useEffect(() => {
    window.addEventListener('resize', setCloseButtonValue);

    return () => {
      window.removeEventListener('resize', setCloseButtonValue);
    };
  }, [setCloseButtonValue]);

  return (
    <BModal
      id={props.id}
      show={show}
      onHide={closeModal}
      onShow={props.handleShow}
      size={size}
      className={
        'w3s-modal-modal' +
        (fullScreen ? ' w3s-modal-fullscreen' : '') +
        (!!props.forceFullScreenOnMedium ? ' w3s-modal-fullscreen-md' : '') +
        (props.className ? ' ' + props.className : '')
      }
      animation={false}
      scrollable={scrollable}
      keyboard={!props.disabled}
      backdrop={checkIfFullScreen() ? 'static' : backdrop}
      centered={!checkIfFullScreen()}
      aria-labelledby={`${myID}-title`}
    >
      {checkIfFullScreen() && (
        <div className='modal-top-row'>
          <div className={'modal-cancel-alternative'}>
            <BackArrowButton text='' disabled={!!props.loading} handleOnClick={closeModal} />
          </div>
          {props.topRowContent}
          {displayOk && (
            <div className='modal-top-ok-group'>
              {!!props.loading && <Spinner size={Spinner.utils.size.sm} className='mr-2' />}

              <div
                className={
                  'modal-ok-alternative' +
                  ' modal-variant-' +
                  classModal +
                  (!!props.disabled || !!props.loading || props.disableOk ? ' disabled' : '')
                }
                tabIndex={!!props.disabled || !!props.loading || props.disableOk ? -1 : 0}
                onClick={onClickOk}
                onKeyDown={(event: React.KeyboardEvent) => {
                  if (isActivationKeyPressed(event)) {
                    event.stopPropagation();
                    event.preventDefault();
                    onClickOk(event);
                  }
                }}
                aria-label={okText}
                aria-disabled={!!props.disabled || !!props.loading || props.disableOk}
              >
                {okText}
              </div>
            </div>
          )}
        </div>
      )}
      <BModal.Header closeButton={closeButton && !props.loading}>
        {props.headerContent}
        <BModal.Title id={`${myID}-title`}>{title}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{props.children}</BModal.Body>
      <BModal.Footer>
        {!!props.preExtraButtons && <div className='modal-extra-buttons justify-content-start modal-full-width'>{props.preExtraButtons}</div>}

        {displayCancel && (
          <ButtonV2
            type={cancelButtonType === 'a' ? 'noframe' : cancelVariant}
            disabled={!!props.loading}
            text={cancelText}
            className='modal-cancel-button'
            onClick={closeModal}
            ariaLabel={cancelText}
          />
        )}

        {!!props.extraButtons && <div className='modal-extra-buttons'>{props.extraButtons}</div>}

        {displayOk && (
          <ButtonV2
            type={okButtonType === 'a' ? 'noframe' : okVariant}
            className='modal-ok-button'
            disabled={!!props.disabled || !!props.loading || props.disableOk}
            text={okText}
            loading={props.loading}
            onClick={onClickOk}
            ariaLabel={okText}
            ariaLabelSpinner={props.ariaLabelSpinner}
          />
        )}

        {!!props.postExtraButtons && <div className='modal-extra-buttons justify-content-end'>{props.postExtraButtons}</div>}
      </BModal.Footer>
    </BModal>
  );
}

export default Modal;
