import React from 'react';
import GoogleTagManager from 'react-gtm-module';
import ReactDOM from 'react-dom';
import { logging } from 'w3-user-ui-component';
import uilibPackageJson from 'w3-user-ui-component/package.json';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import cerateSagaMiddleware from 'redux-saga';

import { rootReducer } from './Redux';
import Base from './Component/Base/Base';
import { fetchMainBulkDataSaga } from './Redux/sagas';
import packageJson from '../package.json';

logging.logAll('Spaces ui version: ', packageJson.version);
logging.logAll('UI library version: ', uilibPackageJson.version);

GoogleTagManager.initialize({
  gtmId: 'GTM-KTCFC3S',
  dataLayer: {
    js: new Date(),
  },
});

const sagaMiddleware = cerateSagaMiddleware();
const composedEnhancer = composeWithDevTools(applyMiddleware(thunk, sagaMiddleware));
const store = createStore(rootReducer, composedEnhancer);

sagaMiddleware.run(fetchMainBulkDataSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Base version={packageJson.version} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// reportWebVitals();
