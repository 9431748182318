import {
    ModalDataType,
    ModalDismissType,
    ModalUpdateType,
    typedAction,
} from '../../Util/InterfaceAndTypeUtil';
import { logging } from 'w3-user-ui-component';

interface ModalReducerStateType {
    modals: ModalDataType[],
}

export const modalInitialState: ModalReducerStateType = {
    modals: [],
}

export const addModal = (modal: ModalDataType) => {
    return typedAction('Modal::add', modal);
}

export const dismissModal = (modal: ModalDismissType) => {
    return typedAction('Modal::dismiss', modal);
}

export const updateModal = (modal: ModalUpdateType) => {
    return typedAction('Modal::update', modal);
}

type ModalAction = ReturnType<
    typeof addModal |
    typeof dismissModal |
    typeof updateModal
>

export function modalReducer(
    state = modalInitialState,
    action: ModalAction,
): ModalReducerStateType {
    switch (action.type) {
        case 'Modal::add':
            logging.logDebug('modalReducer -> Modal::add -> action.payload: ', action.payload);

            return {
                ...state,
                modals: [...state.modals, action.payload]
            };

        case 'Modal::dismiss':
            logging.logDebug('modalReducer -> Modal::dismiss -> action.payload: ', action.payload);

            return {
                ...state,
                modals: state.modals.map((modal: ModalDataType) => {
                    if (modal.id === action.payload.id) {
                        modal.dismissed = true;
                    }

                    return modal;
                })
            };

        case 'Modal::update':
            logging.logDebug('modalReducer -> Modal::update -> action.payload: ', action.payload);

            return {
                ...state,
                modals: state.modals.map((modal: ModalDataType) => {
                    if (modal.id === action.payload.id) {
                        return {...modal, ...action.payload}; //DataUtil.getWithDefaults(action.payload, modal, true) as ModalDataType;
                    }

                    return modal;
                })
            };

        default: return state;
    }
}
