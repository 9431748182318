import { logging } from 'w3-user-ui-component';
import { typedAction } from '../../Util/InterfaceAndTypeUtil';

export interface CreateSpaceStateType {
  spaceId: string;
  template: string;
  server: string;
}

export const createSpaceInitialState: CreateSpaceStateType = {
  spaceId: '',
  template: 'skeleton',
  server: '',
};

export const setSpaceId = (spaceId: string) => {
  return typedAction('CreateSpace::setSpaceId', spaceId);
};

export const setTemplate = (template: string) => {
  return typedAction('CreateSpace::setTemplate', template);
};

export const setServer = (server: string) => {
  return typedAction('CreateSpace::setServer', server);
};

export const resetCreateSpace = () => {
  return typedAction('CreateSpace::reset');
};

type CreateSpaceAction = ReturnType<typeof setSpaceId | typeof setTemplate | typeof resetCreateSpace | typeof setServer>;

export function createSpaceReducer(state = createSpaceInitialState, action: CreateSpaceAction): CreateSpaceStateType {
  switch (action.type) {
    case 'CreateSpace::setSpaceId':
      logging.logDebug('createSpaceReducer -> CreateSpace::setSpaceId -> action.payload: ', action.payload);

      return {
        ...state,
        spaceId: action.payload,
      };

    case 'CreateSpace::setTemplate':
      logging.logDebug('createSpaceReducer -> CreateSpace::setTemplate -> action.payload: ', action.payload);

      return {
        ...state,
        template: action.payload,
      };

    case 'CreateSpace::setServer':
      logging.logDebug('createSpaceReducer -> CreateSpace::setServer -> action.payload: ', action.payload);

      return {
        ...state,
        server: action.payload,
      };

    case 'CreateSpace::reset':
      logging.logDebug('createSpaceReducer -> CreateSpace::reset');

      return createSpaceInitialState;

    default:
      return state;
  }
}
