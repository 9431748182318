import React from 'react';
import { ModalDataType } from '../../Util/InterfaceAndTypeUtil';
import { RootStateType } from '../../Redux';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonV2, util } from 'w3-user-ui-component';
import { dismissModal } from '../../Redux/Reducer/ModalReducer';
import Modal from '../Modal/Modal';

interface IProps {
  className?: string;
}

function ModalHandler(props: IProps) {
  const modals = useSelector((state: RootStateType) => state.modal.modals);
  const dispatch = useDispatch();

  function handleDismissModal(modal: ModalDataType) {
    dispatch(dismissModal({ id: modal.id }));
  }

  const onClickUpgrade = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    document.location.href = util.getUpgradeLink();
  };

  function setFooterItem(modal: ModalDataType) {
    return (
      <>
        {modal.learnMoreButtonText === undefined ? (
          ``
        ) : (
          <ButtonV2
            text={modal.learnMoreButtonText}
            type='light'
            disabled={modal.disabled}
            onClick={onClickUpgrade}
            ariaLabel={modal.learnMoreButtonText}
          />
        )}

        {modal.upgradeButtonText === undefined ? (
          ``
        ) : (
          <ButtonV2
            text={modal.upgradeButtonText}
            type='green'
            disabled={modal.disabled}
            loading={modal.loading}
            onClick={onClickUpgrade}
            ariaLabel={modal.upgradeButtonText}
          />
        )}
      </>
    );
  }

  return (
    <>
      {modals.map((modal: ModalDataType) => {
        return (
          <Modal
            key={modal.id}
            show={!modal.dismissed}
            size='lg'
            title={modal.header}
            displayCancel={false}
            displayOk={!!modal.dismissButtonText}
            okText={modal.dismissButtonText}
            handleOk={() => handleDismissModal(modal)}
            postExtraButtons={setFooterItem(modal)}
            className={props.className ? props.className : undefined}
          >
            {modal.body}
          </Modal>
        );
      })}
    </>
  );
}
export default ModalHandler;
