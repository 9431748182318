import moment from 'moment';

// returns current unix timestamp
export const getCurrentUts = (): number => {
    return Math.round((new Date()).getTime() / 1000)
}

// returns current unix timestamp in milliseconds
export const getCurrentUtms = (): number => {
    return (new Date()).getTime();
}

export const humanTimeAgo = (date: Date): string => {

    const now = new Date()
    const diff = (now.getTime() - date.getTime()) / 1000

    if (diff < 60) {
        return "Just now"
    }

    if (diff < 120) {
        return "a minute ago"
    }

    if (diff < 3600) {
        let minutes = Math.floor(diff / 60)
        return `${minutes} minutes ago`
    }

    if (diff < 7200) {
        return "an hour ago"
    }

    if (diff < 86400) {
        let hours = Math.floor(diff / 3600)
        return `${hours} hours ago`
    }

    if (diff < 172800) {
        return "a day ago"
    }

    if (diff < 604800) {
        const days = Math.floor(diff / 86400)
        return `${days} days ago`
    }

    if (diff < 1209600) {
        return "a week ago"
    }

    if (diff < 2592000) {
        const weeks = Math.floor(diff / 604800)
        return `${weeks} weeks ago`
    }

    if (diff < 5184000) {
        return "a month ago"
    }

    if (diff < 31536000) {
        const months = Math.floor(diff / 2592000)
        return `${months} months ago`
    }

    return "more than a year ago"
}

// May 2, 2021 at 12:00
export const getUtcUtsConvertedToLocalDateTimeStr = (utc_uts: number): string => {
    return moment.unix(utc_uts).local().format('MMM Do, YYYY [at] HH:mm');
    // return moment.unix(utc_uts).local().toISOString();
}

export const getUtcIso8601StrConvertedToUts = (utc_iso8601_str: string): number => {
    return parseInt(moment(utc_iso8601_str).format('X'))
}