import moment from 'moment';
import { ITime, SubscriptionPlanOptionItemType, SubscriptionPlanOptionsType, SubscriptionPlanType } from './InterfaceAndTypeUtil';

export const getSubscriptionPlanTimeSpanEndDts = (subscription_plan: SubscriptionPlanType, format: string = 'MMM DD, YYYY') => {
  return moment.unix(subscription_plan.time_span_end_uts).format(format);
};

export const getSubscriptionPlanTimeSpanEndDtsV2 = (subscription_plan: SubscriptionPlanType, format: string = 'MMM DD, HH:mm:ss'): ITime => {
  return {
    local: moment.unix(subscription_plan.time_span_end_uts).format(format + ' (Z)'),
    gmt: moment.unix(subscription_plan.time_span_end_uts).utc().format(format) + ' GMT',
  };
};

/**
 * Find the title of a subscription plan.
 * @param slug Unique id of the plan
 * @param frequency The frequency of the plan
 * @param subscriptionOption List of plans to check against
 * @returns Returns the title. If no plan is found then slug value is returned.
 */
export const getSubscriptionPlanTitle = (slug: string, frequency: string, subscriptionOption: SubscriptionPlanOptionsType) => {
  let subscriptionId = `${slug}/${frequency}`;

  if (!subscriptionOption[subscriptionId]) {
    subscriptionId = `${slug}/monthly`;
  }

  let subscription: SubscriptionPlanOptionItemType = subscriptionOption[subscriptionId];

  if (subscription) {
    return subscription.title || subscription.cname;
  }

  return slug;
};
