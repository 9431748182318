import React from 'react';
import { IDefault } from 'src/Util/InterfaceAndTypeUtil';

import styles from './MobileMenuChild.module.scss';

interface IMobileMenuChild extends IDefault {
  disabled?: boolean;
  ariaLabel?: string;
  ariaPressed?: boolean;
  ariaChecked?: boolean;
  tabIndex?: number;
  role?: React.AriaRole;
  onClick?: (event: React.MouseEvent) => void;
}

const MobileMenuChild = ({
  tabIndex = 0,
  ariaLabel,
  ariaPressed,
  ariaChecked,
  disabled,
  role,
  id,
  className,
  children,
  onClick,
}: React.PropsWithChildren<IMobileMenuChild>) => {
  return (
    <div
      id={id}
      className={`-menu-item ${styles.menuItem}${disabled ? ` ${styles.disabled}` : ''}${className ? ` ${className}` : ''}`}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      aria-checked={ariaChecked}
      tabIndex={onClick ? tabIndex : -1}
      role={role}
    >
      {children}
    </div>
  );
};

export default MobileMenuChild;
