import React, { useEffect, useMemo, useState } from 'react';
import { IDefault, SpaceStateType, SpaceVariantType } from 'src/Util/InterfaceAndTypeUtil';
import { getSpaceVariantIcon } from './util';

import styles from './VariantHeader.module.scss';

export enum VariantSize {
  XSmall = 'x-sm',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}
interface IVariantHeader extends IDefault {
  title?: string;
  size?: VariantSize;
  hideTitle?: boolean;
  showIcon?: boolean;
  iconType?: SpaceVariantType;
  showDot?: boolean;
  showPadlock?: boolean;
  spaceState?: SpaceStateType;
  disableDynamic?: boolean;
  hideBorder?: boolean;
}

const VariantHeader = ({
  title,
  spaceState,
  showIcon = false,
  showDot = false,
  showPadlock = false,
  iconType,
  hideTitle = false,
  size = VariantSize.Medium,
  disableDynamic = false,
  id,
  className,
  hideBorder = false,
}: IVariantHeader) => {
  const icon = useMemo(() => {
    return getSpaceVariantIcon(hideBorder, iconType);
  }, [hideBorder, iconType]);

  const [displayPadlock, setDisplayPadlock] = useState(false);

  useEffect(() => {
    if (disableDynamic) {
      setDisplayPadlock(true);
    } else if (showPadlock && spaceState) {
      switch (spaceState) {
        case 'INACTIVE':
        case 'BANNED':
        case 'DELETED':
          setDisplayPadlock(true);
          break;
        default:
          setDisplayPadlock(false);
      }
    } else {
      setDisplayPadlock(false);
    }
  }, [disableDynamic, showPadlock, spaceState]);

  return (
    <div
      id={id}
      className={`space-icon-window ${styles.wrapper} ${`size-${size}`}${disableDynamic ? ' dynamic_disabled_dot' : ''}${
        spaceState ? ` ${spaceState}` : ''
      }${displayPadlock ? ` ${styles.showPadlock}` : ''}${!hideTitle ? ` ${styles.o_h}` : ''}${className ? ` ${className}` : ''}`}
    >
      {showIcon && icon}
      {!hideTitle && <div className={styles.title}>{title}</div>}

      {showDot && (
        <svg className={styles.display_svg} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle className='dot' cx='55' cy='51' r='5' />
        </svg>
      )}

      {displayPadlock && (
        <div className={`${styles.display_svg} ${styles.padlock}${iconType === SpaceVariantType.REACT ? ` ${styles.displayed_react}` : ''}`}>
          <svg viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M18.8125 16.375H18.3438V14.9688C18.3438 13.332 17.0117 12 15.375 12C13.7383 12 12.4062 13.332 12.4062 14.9688V16.375H11.9375C11.4199 16.375 11 16.7949 11 17.3125V21.0625C11 21.5801 11.4199 22 11.9375 22H18.8125C19.3301 22 19.75 21.5801 19.75 21.0625V17.3125C19.75 16.7949 19.3301 16.375 18.8125 16.375ZM16.7812 16.375H13.9688V14.9688C13.9688 14.1934 14.5996 13.5625 15.375 13.5625C16.1504 13.5625 16.7812 14.1934 16.7812 14.9688V16.375Z'
              fill='#000000'
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default VariantHeader;
