export interface AiIconProps {
  className?: string;
}

function AiIcon({ className }: AiIconProps) {
  return (
    <svg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg' className={className ? className : ''}>
      <path
        d='M9 18.75C9 18.3358 9.33579 18 9.75 18H14.25C14.6642 18 15 18.3358 15 18.75C15 19.1642 14.6642 19.5 14.25 19.5H9.75C9.33579 19.5 9 19.1642 9 18.75Z'
        fill='#9763f6'
      />
      <path
        d='M4.5 12.0923C4.5 10.1406 6.35206 8.64693 8.29529 8.82876C9.35663 8.92807 10.6064 9 12 9C13.3936 9 14.6434 8.92807 15.7047 8.82876C17.6479 8.64693 19.5 10.1406 19.5 12.0923V13.8288C19.5 14.5171 19.0312 15.1125 18.3531 15.2304C17.0855 15.4509 14.8419 15.75 12 15.75C9.1581 15.75 6.91446 15.4509 5.64687 15.2304C4.96875 15.1125 4.5 14.5171 4.5 13.8288V12.0923ZM11.3129 10.8525C11.1946 10.8324 11.0738 10.8701 10.988 10.9539L9.60643 12.3033C8.51581 12.2377 7.56916 12.1351 6.80094 12.0297C6.59575 12.0016 6.40661 12.1451 6.37848 12.3503C6.35034 12.5555 6.49388 12.7446 6.69906 12.7728C7.52357 12.8858 8.54696 12.9956 9.72905 13.0617C9.83409 13.0676 9.93676 13.0291 10.012 12.9556L11.143 11.8509L12.414 14.4165C12.4682 14.5259 12.5723 14.6021 12.693 14.6207C12.8138 14.6392 12.936 14.5978 13.0205 14.5097L14.4186 13.0532C15.5384 12.9864 16.5109 12.8811 17.3009 12.7728C17.5061 12.7446 17.6497 12.5555 17.6215 12.3503C17.5934 12.1451 17.4043 12.0016 17.1991 12.0297C16.3925 12.1403 15.3892 12.248 14.229 12.3129C14.1343 12.3182 14.0452 12.3592 13.9795 12.4276L12.8491 13.6053L11.586 11.0557C11.5328 10.9482 11.4312 10.8726 11.3129 10.8525Z'
        fill='#9763f6'
      />
      <path
        d='M12.75 2.79933C13.1984 2.53997 13.5 2.05521 13.5 1.5C13.5 0.671573 12.8284 0 12 0C11.1716 0 10.5 0.671573 10.5 1.5C10.5 2.05521 10.8016 2.53997 11.25 2.79933V4.5H8.25C4.52208 4.5 1.5 7.52208 1.5 11.25V12C0.671573 12 0 12.6716 0 13.5V16.5C0 17.3284 0.671573 18 1.5 18V19.5C1.5 21.1569 2.84315 22.5 4.5 22.5H19.5C21.1569 22.5 22.5 21.1569 22.5 19.5V18C23.3284 18 24 17.3284 24 16.5V13.5C24 12.6716 23.3284 12 22.5 12V11.25C22.5 7.52208 19.4779 4.5 15.75 4.5H12.75V2.79933ZM21 11.25V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V11.25C3 8.35051 5.35051 6 8.25 6H15.75C18.6495 6 21 8.35051 21 11.25Z'
        fill='#9763f6'
      />
    </svg>
  );
}

export default AiIcon;
