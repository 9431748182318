import { useLayer, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import './PopoverMenu.scss';
import { v4 } from 'uuid';
import { isActivationKeyPressed } from 'src/Util/MixedUtil';
import { current } from '@reduxjs/toolkit';

interface PopoverMenuPropsType {
  triggerElm: React.ReactElement;
  children: React.ReactElement;
  disabled?: boolean;
  placement?: PlacementType;
  className?: string;
  triggerWrapperClassName?: string;
  onDisappear?: () => void;
  onOutsideClick?: () => void;
  openMenu?: boolean;
  onBlurMenu?: () => void;
  tabIndex?: number;
  ariaLabel?: string;
  onOpenValueChanged?: (value: boolean) => void;
}

function PopoverMenu({
  triggerElm,
  children,
  disabled = false,
  openMenu = false,
  placement = 'bottom-end',
  className,
  triggerWrapperClassName,
  tabIndex,
  ariaLabel,
  onOutsideClick,
  onDisappear,
  onBlurMenu,
  onOpenValueChanged,
}: PopoverMenuPropsType) {
  const usedKeyboard = useRef(false);

  const popoverEl = useRef<HTMLDivElement | null>(null);
  const triggerEl = useRef<HTMLDivElement | null>(null);
  const firstChild = useRef<any>();
  const lastChild = useRef<any>();

  const [isOpen, setIsOpen] = React.useState(false);

  const triggerId = useMemo(() => {
    return `popover-menu-trigger-elm-wrp-${v4()}`;
  }, []);

  const popoverId = useMemo(() => {
    return `popover-menu-wrp-${v4()}`;
  }, []);

  // helper function to close the menu
  function disappear() {
    setIsOpen(false);
    onDisappear && onDisappear();
  }

  function outsideClick() {
    setIsOpen(false);
    onOutsideClick && onOutsideClick();
  }

  /**
   * Get meny elements and current index in elements array if it is one of the menu items that has focus
   * @returns
   */
  const getMenuItemElements = () => {
    const elementsWithTabIndex = popoverEl.current?.querySelectorAll('button, [tabindex]:not([tabindex="-1"])') || [];
    const elementArray: Element[] = [];
    let currentIndex = -1;

    if (elementsWithTabIndex.length > 0) {
      elementsWithTabIndex.forEach((element, index) => {
        const computedStyle = getComputedStyle(element);
        if (computedStyle.display !== 'none') {
          elementArray.push(element);
          if (element === document.activeElement) {
            currentIndex = elementArray.length - 1;
          }
        }
      });
    }

    return { array: elementArray, currentIndex: currentIndex };
  };

  /**
   * Go to next/previous item. Go in a circle if we are at any of the endpoints.
   * @param goNext Go to next or previous item
   * @return True if we focused on a element
   */
  const focusNextElement = (goNext: boolean) => {
    let focusedElement = false;

    const elObject = getMenuItemElements();
    const elementArray: Element[] = elObject.array;
    const currentIndex = elObject.currentIndex;

    if (currentIndex > -1) {
      let index = currentIndex;

      if (goNext) {
        if (currentIndex === elementArray.length - 1) {
          index = 0;
        } else {
          index += 1;
        }
      } else {
        if (currentIndex === 0) {
          index = elementArray.length - 1;
        } else {
          index -= 1;
        }
      }

      (elementArray[index] as HTMLDivElement).focus();
      focusedElement = true;
    }

    return focusedElement;
  };

  const onKeyDownPopup = (event: React.KeyboardEvent) => {
    console.log(event.code);

    if (event.code === 'Escape') {
      usedKeyboard.current = true;
      event.stopPropagation();
      setIsOpen(false);
    } else if (event.code === 'ArrowDown') {
      event.stopPropagation();
      event.preventDefault();

      if (!focusNextElement(true)) {
        firstChild.current && firstChild.current!.focus();
      }
    } else if (event.code === 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();

      if (!focusNextElement(false)) {
        lastChild.current && lastChild.current!.focus();
      }
    } else if (event.code === 'Tab') {
      event.stopPropagation();
      event.preventDefault();

      /* if (event.shiftKey) {
        if (firstChild.current && firstChild.current === document.activeElement) {
          // going back from first element -> focus on last element
          event.stopPropagation();
          event.preventDefault();
          //lastChild && lastChild.current!.focus();
          popoverEl.current!.focus();
        } else if (popoverEl.current === document.activeElement) {
          event.stopPropagation();
          event.preventDefault();
          lastChild.current && lastChild.current!.focus();
        }
      } else if (lastChild.current && lastChild.current === document.activeElement) {
        // going forward from last element -> focus on first element
        event.stopPropagation();
        event.preventDefault();
        //firstChild.current && firstChild.current!.focus();
        popoverEl.current!.focus();
      } */
    }
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: outsideClick, // close the menu when the user clicks outside
    onDisappear: disappear, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: placement, // we prefer to place the menu 'top-end'
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  useEffect(() => {
    setIsOpen(openMenu);
  }, [openMenu]);

  const lastIsOpenValueSent = useRef(false);
  useEffect(() => {
    if (isOpen !== lastIsOpenValueSent.current) {
      onOpenValueChanged && onOpenValueChanged(isOpen);
      lastIsOpenValueSent.current = isOpen;
    }
  }, [isOpen, onOpenValueChanged]);

  useEffect(() => {
    if (isOpen) {
      usedKeyboard.current = false;
      popoverEl.current = document.getElementById(popoverId) as HTMLDivElement;

      firstChild.current = undefined;

      if (!firstChild.current) {
        const elObject = getMenuItemElements();
        const elementArray: Element[] = elObject.array;
        firstChild.current = elementArray[0];
        lastChild.current = elementArray[elementArray.length - 1];
      }

      popoverEl.current.focus();
    } else {
      popoverEl.current = null;

      if (usedKeyboard.current) {
        usedKeyboard.current = false;
        triggerEl.current!.focus();
        onBlurMenu && onBlurMenu();
      }
    }
  }, [isOpen, onBlurMenu, popoverId, triggerId]);

  useEffect(() => {
    if (triggerId) {
      triggerEl.current = document.getElementById(triggerId) as HTMLDivElement;
    }
  }, [triggerId]);

  const onBlurButton = () => {
    if (!isOpen) {
      // check if any of wrappers children has focus
      onBlurMenu && onBlurMenu();
    }
  };

  // Again, we're using framer-motion for the transition effect
  return (
    <>
      <div
        id={triggerId}
        className={`popover-menu-trigger-elm-wrp${triggerWrapperClassName ? ` ${triggerWrapperClassName}` : ''}`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setIsOpen(!isOpen && !disabled);
        }}
        onKeyDown={(event) => {
          if (isActivationKeyPressed(event) && !event.shiftKey) {
            event.stopPropagation();
            event.preventDefault();
            setIsOpen(!isOpen && !disabled);
          }
        }}
        {...triggerProps}
        tabIndex={tabIndex || 0}
        aria-label={ariaLabel ? ariaLabel : 'More'}
        aria-disabled={disabled}
        onBlur={onBlurButton}
      >
        {triggerElm}
      </div>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              id={popoverId}
              className={`popover-menu-wrp${className ? ` ${className}` : ''}`}
              {...layerProps}
              role='menu'
              tabIndex={0}
              onKeyDown={onKeyDownPopup}
              aria-label='Sub menu group'
            >
              {children}
              <Arrow borderWidth={1} borderColor='#E7E9EB' {...arrowProps} />
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </>
  );
}

export default PopoverMenu;
