import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import { addNotificationError } from './Reducer/NotificationReducer';
import {
    setLoadingState,
    removeLoadingState,
    setMainBulkState,
    updateMainBulkState,
} from './Reducer/MainReducer';
import { MainStateType } from '../Util/InterfaceAndTypeUtil';
import { fetchSpaces, logging } from 'w3-user-ui-component';


function* fetchMainBulkDataAction(action: any) {
    yield put(setLoadingState('fetchMainBulkDataAction'));

    // @ts-ignore
    const reqRes = yield fetchSpaces<MainStateType>({
        url: '/userinfo',
        method: 'POST',
        data: {
            action: 'getMainBulkData'
        }
    });

    logging.logDebug('sagas -> fetchMainBulkDataAction -> reqRes: ', reqRes);

    if (reqRes.error.code !== '0') {
        yield put(addNotificationError(reqRes.error));
        return;
    }

    const reqResData = reqRes.data;

    if (
        typeof reqResData === 'undefined'
        || reqResData === null
        || typeof reqResData.user === 'undefined'
        || typeof reqResData.subscription_plan_options === 'undefined'
        // || typeof main_bulk_data.subscription_plan === 'undefined'
        // || typeof main_bulk_data.quota === 'undefined'
        // || typeof main_bulk_data.usage === 'undefined'
        // || typeof main_bulk_data.usage_status === 'undefined'
        // || typeof main_bulk_data.spaces === 'undefined'
    ) {
        yield put(addNotificationError({
            code: 'INTERNAL_SERVER_ERROR',
            description: 'Received a malformed server response'
        }));
        return;
    }

    if (typeof reqResData.subscription_plan !== 'undefined') { // registered
        yield put(setMainBulkState(reqResData));
    } else {
        yield put(updateMainBulkState(reqResData));
    }

    yield put(removeLoadingState('fetchMainBulkDataAction'));
}

export function* fetchMainBulkDataSaga() {
    yield takeEvery('Main::fetchBulkData', fetchMainBulkDataAction);
}

// function* deprecatedFetchFiles(action: any) {
//     // @ts-ignore
//     const fileResponse = yield request.get('/files')

//     const fileResponseError = handleResponseErrorSync(fileResponse)

//     if (fileResponseError !== undefined) {
//         // TODO: (mid) implement the improved response structure in indy_authorizer
//         if (fileResponseError.code === 'FINECIS') {
//             const expiry = getTokenExpiry()

//             if (expiry && expiry < new Date()) {
//                 yield put(addFatalError(`Your accesstoken expired at ${expiry.toLocaleDateString()} at ${expiry.toLocaleTimeString()}. Please try to refresh, or log in and out of your account.`))
//             }

//             return
//         }

//         yield put(addError(fileResponseError))

//         return
//     }

//     const spaces = fileResponse.data.map((space: any) => {
//         space.files = space.files.map((file: BackendFileType) => {
//             return File.getProcessedMetaFromBackendFile(file)
//         })

//         return space
//     }) as Space[]

//     for (let space of spaces) {
//         yield put(addSpace(space))
//     }
// }

// export function* deprecatedFetchFilesSaga() {
//     yield takeEvery("Main/Space/Files::fetch", deprecatedFetchFiles)
// }
