import {
  FileType,
  SpaceType,
  MainStateType,
  typedAction,
  UpdateSpaceType,
  UserInfoType,
  BackendFileType,
  Optional,
  QuotaType,
  UsageType,
  UsageStatusType,
  SubscriptionPlanType,
  SubscriptionPlanOptionsType,
  SubscriptionPlanOptionItemType,
  OptionalMainStateType,
  RequestResponseType,
  FileProcessingStatuses,
  EditorContentType,
  SpaceContentStateType,
  KaiTokenType,
  AiContentStateType,
  AiFetchEnum,
  FileProcessingStatusErrorType,
  PricesStatus,
  FeatureFlagsType,
  IAiChat,
  IAiResponseType,
} from '../../Util/InterfaceAndTypeUtil';
import { Dispatch, AnyAction } from 'redux';
import * as DataUtil from '../../Util/DataUtil';
// import { getTokenExpiry } from '../../Util/SessionUtil';
import { addNotificationError } from './NotificationReducer';
import { fetchSpaces, logging, setDefaultFetchConfig } from 'w3-user-ui-component';
import * as FileUtil from '../../Util/FileUtil';
import { getCurrentUts } from 'src/Util/TimeUtil';
import { FeatureFlags } from 'src/Util/SpaceUtil';
// import DUMMY_FILES from './DummyFiles.local.json'

export const mainInitialState: MainStateType = {
  loading: [],
  initialized: false,
  fully_initialized: false,
  partially_initialized: false,
  user: {
    is_registered: false,
    onboarding_step: 1,
    id: '',
  } as UserInfoType,
  subscription_plan_options: {} as SubscriptionPlanOptionsType,
  subscription_plan_option: {} as SubscriptionPlanOptionItemType,
  subscription_plan: {} as SubscriptionPlanType,
  quota: {} as QuotaType,
  usage: {} as UsageType,
  usage_status: {} as UsageStatusType,
  spaces: [],
  files_uploaded: [],
  space_path: '',
  display_footer: true,
  display_header: true,
  editor_content: {
    url: null,
    file_path: null,
    domainNameForSpace: null,
    useRequestsOnPreview: false,
    backFromEditor: false,
    filesAreLoaded: false,
    textToInsertEditor: undefined,
  },
  space_content_state: {
    loadingFiles: false,
    prepareingDynamic: false,
    isSpaceAllSetUp: false,
  },
  ai_content: {
    aiContentArray: [],
    aiSelectedCode: undefined,
    type: AiFetchEnum.NONE,
    language: undefined,
    loading: false,
    prices: {
      status: PricesStatus.NOT_YET_REQUESTED,
      value: {},
    },
  },
  kaiToken: {
    wallet: 1000000,
    estimatedCost: undefined,
  },
  featureFlags: {
    noUpsell: false,
    adFree: false,
  },
};

// < Main actions
export const setFeatureFlags = (flags: Optional<FeatureFlagsType>) => {
  return typedAction('Main::setFeatureFlags', flags);
};

export const setAiContent = (newValue: Optional<AiContentStateType>) => {
  return typedAction('Main::setAiContent', newValue);
};

export const updateAiChatItemResponse = (newValue: { id: string; response: Optional<IAiResponseType>; lastItem?: boolean }) => {
  return typedAction('Main::updateAiChatItemResponse', newValue);
};

export const changeAiChatItemId = (values: { newId: string; oldId: string }) => {
  return typedAction('Main::changeAiChatItemId', values);
};

export const setKaiTokens = (newValue: Optional<KaiTokenType>) => {
  return typedAction('Main::setKaiTokens', newValue);
};

export const setEditorContent = (newValue: Optional<EditorContentType>) => {
  return typedAction('Main::setEditorContent', newValue);
};

export const setSpaceContentState = (newValue: Optional<SpaceContentStateType>) => {
  return typedAction('Main::setSpaceContentState', newValue);
};

export const setDisplayFooter = (newValue: boolean) => {
  return typedAction('Main::setDisplayFooter', newValue);
};

export const setDisplayHeader = (newValue: boolean) => {
  return typedAction('Main::setDisplayHeader', newValue);
};

export const fetchMainBulkData = () => {
  return typedAction('Main::fetchBulkData');
};

export const setMainBulkState = (main_bulk_data: MainStateType) => {
  return typedAction('Main::setBulkData', main_bulk_data);
};

export const updateMainBulkState = (main_bulk_data: Optional<MainStateType>) => {
  return typedAction('Main::updateBulkData', main_bulk_data);
};

export const updateUserInfoState = (user_info: Optional<UserInfoType>) => {
  return typedAction('Main/User::update', user_info);
};

export const setLoadingState = (executing_entity_name: string) => {
  return typedAction('Main::setLoading', executing_entity_name);
};

export const removeLoadingState = (executing_entity_name: string) => {
  return typedAction('Main::removeLoading', executing_entity_name);
};

export const addFatalError = (error: string) => {
  return typedAction('Main::addFatalError', error);
};
// > Main actions

// < Space actions
export const setSpaces = (spaces: SpaceType[]) => {
  return typedAction('Main/Spaces::set', spaces);
};

export const addSpace = (space: SpaceType) => {
  return typedAction('Main/Space::add', space);
};

export const removeSpace = (spaceId: string) => {
  return typedAction('Main/Space::remove', spaceId);
};

export const updateSpace = (prs: UpdateSpaceType) => {
  return typedAction('Main/Space::update', prs);
};

export const setSpacePath = (path: string | undefined) => {
  return typedAction('Main/Space::setSpacePath', path);
};

// > Space actions

// < Space files actions
// export const deprecatedFetchFiles = () => {
//     return typedAction('Main/Space/Files::fetch')
// }

export const setFilesAndFolders = (prs: { space_id: string; backend_files: BackendFileType[]; backend_folders: BackendFileType[] }) => {
  return typedAction('Main/Space/FilesAndFolders::set', prs);
};

export const addFiles = (files: FileType[]) => {
  return typedAction('Main/Space/Files::add', files);
};

export const deleteFile = (file: FileType) => {
  return typedAction('Main/Space/File::delete', file);
};

export const deleteFileUploaded = (file: FileType) => {
  return typedAction('Main/Space/File::deleteUploaded', file);
};

export const setProcessingStatus = (
  file: FileType | string,
  processing_status: FileProcessingStatuses | undefined,
  processing_status_error?: FileProcessingStatusErrorType,
) => {
  return typedAction('Main/Space/File::setProcessingStatus', {
    file,
    processing_status: processing_status,
    processing_status_error: processing_status_error,
  });
};

export const resetFileUpload = () => {
  return typedAction('Main/Space/File::resetFileUpload');
};

export const deleteFileByPath = (file_path: string) => {
  return typedAction('Main/Space/File::deleteByPath', file_path);
};

export const moveFiles = (files: FileType[], newPath: string) => {
  return typedAction('Main/Space/Files::move', { files, newPath });
};

export const addFolder = (folder: FileType) => {
  return typedAction('Main/Space/Files::addFolder', folder);
};

export const fetchFiles = (space_id: string, main_state: MainStateType, onSuccessCallback?: (req_res: RequestResponseType) => any) => {
  // TODO: (low) investigate on how to return the response and get rid of callback approach
  // suggested way: Promise.resolve().then(() =>
  return async (dispatch: Dispatch<AnyAction>) => {
    // TODO: (mid) implement user session redirects in another way
    // const access_token_expiry = getTokenExpiry();

    // if (access_token_expiry && access_token_expiry < new Date()) {
    //     // dispatch(addFatalError(`Your accesstoken expired at ${access_token_expiry.toLocaleDateString()}}. Please try to refresh, or log in and out of your account.`));
    //     redirectToLogin();

    //     return;
    // }

    dispatch(setLoadingState('fetchFiles'));

    await _fetchFiles(dispatch, space_id, main_state, onSuccessCallback);

    dispatch(removeLoadingState('fetchFiles'));
  };
};

const _fetchFiles = async (
  dispatch: Dispatch,
  space_id: string,
  main_state: MainStateType,
  onSuccessCallback?: (req_res: RequestResponseType) => any,
): Promise<RequestResponseType> => {
  const req_res = await fetchSpaces<any>({
    url: '/files',
    method: 'POST',
    data: {
      action: 'getFiles',
      space_id: space_id,
      _out_of_quota: typeof main_state !== 'undefined' ? main_state.usage_status._out_of_quota : null,
    },
  });

  logging.logDebug('MainReducer -> _fetchFiles -> req_res: ', req_res);

  let req_res_data = req_res.data;
  const req_res_error = req_res.error;

  // < DEV
  // logging.logDebug('DUMMY_FILES: ', DUMMY_FILES);
  // req_res_data = DUMMY_FILES.data;
  // > DEV

  if (typeof req_res_data._update_main_state !== 'undefined') {
    dispatch(updateMainBulkState(req_res_data._update_main_state as OptionalMainStateType));
  }

  if (typeof req_res_data._update_space !== 'undefined' && req_res_data._update_space !== null) {
    dispatch(updateSpace(req_res_data._update_space as UpdateSpaceType));
  }

  if (req_res_error.code !== '0') {
    dispatch(addNotificationError(req_res_error));

    return req_res;
  }

  dispatch(
    setFilesAndFolders({
      space_id,
      backend_files: req_res_data.files,
      backend_folders: req_res_data.folders,
    }),
  );

  if (typeof onSuccessCallback !== 'undefined') {
    onSuccessCallback(req_res);
  }

  return req_res;
};
// > Space files actions

type MainAction = ReturnType<
  | typeof setFeatureFlags
  | typeof setAiContent
  | typeof updateAiChatItemResponse
  | typeof changeAiChatItemId
  | typeof setKaiTokens
  | typeof setEditorContent
  | typeof setSpaceContentState
  | typeof setDisplayFooter
  | typeof setDisplayHeader
  | typeof setMainBulkState
  | typeof updateMainBulkState
  | typeof updateUserInfoState
  | typeof setLoadingState
  | typeof removeLoadingState
  | typeof addFatalError
  | typeof setSpaces
  | typeof addSpace
  | typeof removeSpace
  | typeof updateSpace
  | typeof setFilesAndFolders
  | typeof addFiles
  | typeof deleteFile
  | typeof deleteFileUploaded
  | typeof setProcessingStatus
  | typeof resetFileUpload
  | typeof setSpacePath
>;

const _getSubscriptionPlanOption = (subscription_plan_options: SubscriptionPlanOptionsType, subscription_plan: SubscriptionPlanType | undefined) => {
  let subscriptionId = '';

  if (subscription_plan) {
    subscriptionId = `${subscription_plan.name}/${subscription_plan.frequency}`;

    if (typeof subscription_plan_options[subscriptionId] === 'undefined') {
      subscriptionId = `${subscription_plan.name}/monthly`;
    }
  }

  if (!subscriptionId || typeof subscription_plan_options[subscriptionId] === 'undefined') {
    subscriptionId = 'free/monthly';
  }

  return subscription_plan_options[subscriptionId];
};

export function mainReducer(state = mainInitialState, action: MainAction): MainStateType {
  let data = null;
  let new_state = null;

  switch (action.type) {
    case 'Main::setBulkData':
      logging.logDebug('mainReducer -> Main::setBulkData -> action.payload: ', action.payload);

      data = action.payload as MainStateType;

      // size in bytes
      const fetchMaxContentLength = data.quota.storage.max_file_size * 1024;

      setDefaultFetchConfig({
        maxContentLength: fetchMaxContentLength,
        maxBodyLength: fetchMaxContentLength,
      });

      new_state = {
        ...state,
        initialized: true,
        fully_initialized: true,
        partially_initialized: false,
        user: data.user,
        subscription_plan_options: data.subscription_plan_options,
        subscription_plan_option: _getSubscriptionPlanOption(data.subscription_plan_options, data.subscription_plan),
        subscription_plan: data.subscription_plan,
        quota: data.quota,
        usage: data.usage,
        usage_status: data.usage_status,
        spaces: data.spaces.map((space: SpaceType) => {
          if (typeof space.files !== 'undefined' && space.files.length) {
            space.files = space.files.map((backend_file: BackendFileType) => {
              return FileUtil.getProcessedMetaFromBackendFile(backend_file);
            });
          }

          return space;
        }),
        github_status: data.github_status,
        github_data: data.github_data,
        kaiToken: {
          estimatedCost: undefined,
          wallet: (FeatureFlags.ai && data.usage.kai.tokens) || 0,
        },
      };

      return new_state;

    case 'Main::updateBulkData':
      logging.logDebug('mainReducer -> Main::updateBulkData -> action.payload: ', action.payload);

      data = action.payload as Optional<MainStateType>;

      new_state = DataUtil.getADeepCopy(state) as MainStateType;

      if (!new_state.fully_initialized) {
        new_state.partially_initialized = true;
      }

      if (!new_state.initialized) {
        new_state.initialized = true;
      }

      if (typeof data.user !== 'undefined') {
        new_state.user = DataUtil.getMerged(state.user, data.user) as MainStateType['user'];
      }

      if (typeof data.subscription_plan_options !== 'undefined') {
        // new_state.subscription_plan_options = DataUtil.getMerged(state.subscription_plan_options, data.subscription_plan_options) as MainStateType['subscription_plan_options'];
        new_state.subscription_plan_options = data.subscription_plan_options;
      }

      if (typeof data.subscription_plan !== 'undefined') {
        // new_state.subscription_plan = DataUtil.getMerged(state.subscription_plan, data.subscription_plan) as MainStateType['subscription_plan'];
        new_state.subscription_plan = data.subscription_plan;
      }

      new_state.subscription_plan_option = _getSubscriptionPlanOption(new_state.subscription_plan_options || data.subscription_plan_options, new_state.subscription_plan || data.subscription_plan);

      if (typeof data.quota !== 'undefined') {
        // size in bytes
        const fetchMaxContentLength = data.quota.storage.max_file_size * 1024;

        setDefaultFetchConfig({
          maxContentLength: fetchMaxContentLength,
          maxBodyLength: fetchMaxContentLength,
        });

        // new_state.quota = DataUtil.getMerged(state.quota, data.quota) as MainStateType['quota'];
        new_state.quota = data.quota;
      }

      if (typeof data.usage !== 'undefined') {
        // new_state.usage = DataUtil.getMerged(state.usage, data.usage) as MainStateType['usage'];
        new_state.usage = data.usage;

        if (FeatureFlags.ai && data.usage.kai) {
          const newKaiToken = { ...state.kaiToken };
          newKaiToken.wallet = data.usage.kai.tokens || 0;
          new_state.kaiToken = { ...newKaiToken };
        }
      }

      if (typeof data.usage_status !== 'undefined') {
        // new_state.usage_status = DataUtil.getMerged(state.usage_status, data.usage_status) as MainStateType['usage_status'];
        new_state.usage_status = data.usage_status;
      }

      if (typeof data.spaces !== 'undefined') {
        // new_state.spaces = DataUtil.getMerged(state.spaces, data.spaces) as MainStateType['spaces'];
        new_state.spaces = state.spaces.map((space: SpaceType) => {
          if (typeof space.files !== 'undefined' && space.files.length > 0) {
            space.files = space.files.map((backend_file: BackendFileType) => {
              return FileUtil.getProcessedMetaFromBackendFile(backend_file);
            });
          }

          return space;
        });
      }

      if (typeof data.github_status !== 'undefined') {
        new_state.github_status = data.github_status;
      }

      if (typeof data.github_data !== 'undefined') {
        new_state.github_data = data.github_data;
      }

      return new_state;

    case 'Main::setFeatureFlags':
      logging.logDebug('mainReducer -> Main::setFeatureFlags -> action.payload: ', action.payload);
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          ...action.payload,
        },
      };

    case 'Main::setAiContent':
      logging.logDebug('mainReducer -> Main::setAiContent -> action.payload: ', action.payload);

      return {
        ...state,
        ai_content: {
          ...state.ai_content,
          ...action.payload,
        },
      };

    case 'Main::updateAiChatItemResponse':
      // will update the first response item in the list. Used by streaming of ai chat which has only one item in list.
      logging.logDebug('mainReducer -> Main::updateAiChatItemResponse -> action.payload: ', action.payload);
      let newAiContentArray: IAiChat[] = [];
      const lastItemInList = action.payload.lastItem || false;

      if (state.ai_content.aiContentArray) {
        newAiContentArray = state.ai_content.aiContentArray.map((item: IAiChat) => {
          if (item.id === action.payload.id) {
            const myIndex = lastItemInList ? item.response.length - 1 : 0;
            const responseArray = [...item.response];

            responseArray.splice(myIndex, 1, { ...responseArray[myIndex], ...action.payload.response });

            const newItem = { ...item, response: responseArray };
            return newItem;
          }
          return item;
        });
      }

      return {
        ...state,
        ai_content: {
          ...state.ai_content,
          aiContentArray: newAiContentArray,
        },
      };

    case 'Main::changeAiChatItemId':
      logging.logDebug('mainReducer -> Main::changeAiChatItemId -> action.payload: ', action.payload);
      let newContentArray: IAiChat[] = [];

      if (state.ai_content.aiContentArray) {
        newContentArray = state.ai_content.aiContentArray.map((item: IAiChat) => {
          if (item.id === action.payload.oldId) {
            const newItem = { ...item, id: action.payload.newId };
            return newItem;
          }
          return item;
        });
      }

      return {
        ...state,
        ai_content: {
          ...state.ai_content,
          aiContentArray: newContentArray,
        },
      };

    case 'Main::setKaiTokens':
      logging.logDebug('mainReducer -> Main::setKaiTokens -> action.payload: ', action.payload);

      return {
        ...state,
        kaiToken: {
          ...state.kaiToken,
          ...action.payload,
        },
      };

    case 'Main::setEditorContent':
      logging.logDebug('mainReducer -> Main::setEditorContent -> action.payload: ', action.payload);

      return {
        ...state,
        editor_content: {
          ...state.editor_content,
          ...action.payload,
        },
      };

    case 'Main::setSpaceContentState':
      logging.logDebug('mainReducer -> Main::setSpaceContentState -> action.payload: ', action.payload);

      return {
        ...state,
        space_content_state: {
          ...state.space_content_state,
          ...action.payload,
        },
      };

    case 'Main::setDisplayFooter':
      logging.logDebug('mainReducer -> Main::setDisplayFooter -> action.payload: ', action.payload);

      return {
        ...state,
        display_footer: action.payload,
      };

    case 'Main::setDisplayHeader':
      logging.logDebug('mainReducer -> Main::setDisplayHeader -> action.payload: ', action.payload);

      return {
        ...state,
        display_header: action.payload,
      };

    case 'Main/User::update':
      logging.logDebug('mainReducer -> Main/User::update -> action.payload: ', action.payload);

      return {
        ...state,
        user: DataUtil.getMerged(state.user, action.payload) as UserInfoType,
      };

    case 'Main/Spaces::set':
      logging.logDebug('mainReducer -> Main/Spaces::set -> action.payload: ', action.payload);

      const new_spaces = action.payload;

      return {
        ...state,
        spaces: new_spaces.map((space: SpaceType) => {
          if (typeof space.files !== 'undefined' && space.files.length) {
            space.files = space.files.map((backend_file: BackendFileType) => {
              return FileUtil.getProcessedMetaFromBackendFile(backend_file);
            });
          }

          return space;
        }),
      };

    case 'Main/Space::add':
      logging.logDebug('mainReducer -> Main/Space::add -> action.payload: ', action.payload);

      const newSpace = action.payload;
      const spaceExists = state.spaces.filter((space: SpaceType) => space.id === newSpace.id);

      if (newSpace.files !== undefined) {
        if (newSpace.files.length) {
          newSpace.files = newSpace.files.map((fileObj: BackendFileType) => {
            return FileUtil.getProcessedMetaFromBackendFile(fileObj);
          });
        }

        newSpace.files = newSpace.files.sort(FileUtil.compareFileTypesByModified);
      }

      if (spaceExists.length > 0) {
        let updatedSpace = spaceExists[0];

        if (newSpace.files) {
          updatedSpace.files = newSpace.files;
        }

        return {
          ...state,
          spaces: state.spaces.map((space: SpaceType) => {
            if (space.id === updatedSpace.id) {
              return updatedSpace;
            }
            return space;
          }),
        };
      }

      return {
        ...state,
        spaces: [...state.spaces, newSpace],
      };

    case 'Main/Space::remove':
      logging.logDebug('mainReducer -> Main/Space::remove -> action.payload: ', action.payload);

      const removeSpaceId = action.payload;

      return {
        ...state,
        spaces: state.spaces.filter((space: SpaceType) => space.id !== removeSpaceId),
      };

    case 'Main/Space::setSpacePath':
      logging.logDebug('mainReducer -> Main/Space::setSpacePath -> action.payload: ', action.payload);

      return {
        ...state,
        space_path: action.payload,
      };

    case 'Main/Space::update':
      logging.logDebug('mainReducer -> Main/Space::update -> action.payload: ', action.payload);

      const new_space = action.payload;
      const files_uploaded = state.files_uploaded;

      const spaces = state.spaces.map((space: SpaceType) => {
        if (space.id === new_space.id) {
          if (typeof new_space.state !== 'undefined') {
            space.state = new_space.state;
          }

          if (typeof new_space.folders !== 'undefined') {
            space.folders = new_space.folders;
          }

          if (typeof new_space.dynamicMeta !== 'undefined') {
            space.dynamicMeta = new_space.dynamicMeta;
          }

          if (typeof new_space.files !== 'undefined') {
            const newFilesList: FileType[] = new_space.files.map((backend_file: BackendFileType) => {
              const newFile: FileType = FileUtil.getProcessedMetaFromBackendFile(backend_file);

              if (files_uploaded.length > 0) {
                // remove the file from the uploaded list if present in the space file list
                const index = files_uploaded.findIndex((uploaded_file) => {
                  return uploaded_file.path === backend_file.path;
                });

                if (index > -1) {
                  if (files_uploaded[index].processing_status === FileProcessingStatuses.Active) {
                    // file is finished uploading
                    newFile.processing_status = FileProcessingStatuses.Uploaded;
                    newFile.processing_status_uts = getCurrentUts();
                    newFile.last_modified = files_uploaded[index].last_modified;
                  }
                  files_uploaded.splice(index, 1);
                }
              }

              if (!newFile.last_modified) {
                // is undefined or null from the server. Check if existing file has a value and use that
                const existingFile: FileType | undefined = space.files.find((originalFile: FileType) => originalFile.path === newFile.path);
                if (existingFile) {
                  newFile.last_modified = existingFile.last_modified;
                }
              }

              return newFile;
            });

            space.files = newFilesList;
          }

          if (typeof new_space.usage !== 'undefined') {
            space.usage = new_space.usage;
          }

          if (typeof new_space.finalize_deletion_uts !== 'undefined') {
            space.finalize_deletion_uts = new_space.finalize_deletion_uts;
          }

          if (new_space.templateId) {
            space.templateId = new_space.templateId;
          }

          if (new_space.github) {
            space.github = new_space.github;
          }
        }

        return space;
      });

      return {
        ...state,
        spaces: spaces,
        files_uploaded: files_uploaded,
      };

    case 'Main/Space/FilesAndFolders::set':
      logging.logDebug('mainReducer -> Main/Space/FilesAndFolders::set -> action.payload: ', action.payload);

      const { space_id, backend_files, backend_folders } = action.payload;
      const uploaded_files = state.files_uploaded;
      const mySpaces = state.spaces.map((space: SpaceType) => {
        if (space.id === space_id) {
          if (backend_files.length) {
            const newFilesList = backend_files.map((backend_file: BackendFileType) => {
              const newFile: FileType = FileUtil.getProcessedMetaFromBackendFile(backend_file);
              // logging.logDebug('Main/Space/FilesAndFolders::set -> newFile: ', newFile);

              if (uploaded_files.length > 0) {
                // remove the file from the uploaded list if present in the space file list
                const index = uploaded_files.findIndex((uploaded_file) => {
                  return uploaded_file.path === backend_file.path;
                });

                if (index > -1) {
                  if (uploaded_files[index].processing_status === FileProcessingStatuses.Active) {
                    // file is finished uploading
                    newFile.processing_status = FileProcessingStatuses.Uploaded;
                    newFile.processing_status_uts = getCurrentUts();
                    newFile.last_modified = uploaded_files[index].last_modified;
                  }
                  uploaded_files.splice(index, 1);
                }
              }

              if (!newFile.last_modified) {
                // is undefined or null from the server. Check if existing file has a value and use that
                const existingFile: FileType | undefined = space.files.find((originalFile: FileType) => originalFile.path === newFile.path);
                if (existingFile) {
                  newFile.last_modified = existingFile.last_modified;
                }
              }

              return newFile;
            });

            space.files = newFilesList;
          } else {
            space.files = [];
          }

          space.folders = backend_folders as FileType[];
        }

        return space;
      });

      return {
        ...state,
        spaces: mySpaces,
        files_uploaded: uploaded_files,
      };

    case 'Main/Space/Files::add':
      logging.logDebug('mainReducer -> Main/Space/FilesAndFolders::add -> action.payload: ', action.payload);

      const files_to_add = action.payload.map((item) => {
        item.last_modified = new Date();
        return item;
      });

      return {
        ...state,
        files_uploaded: state.files_uploaded.concat(files_to_add),
      };

    case 'Main/Space/File::deleteUploaded':
      logging.logDebug('mainReducer -> Main/Space/File::deleteUploaded -> action.payload: ', action.payload);

      const fileToRemove = action.payload;

      return {
        ...state,
        files_uploaded: state.files_uploaded.filter((file) => {
          return file.path !== fileToRemove.path;
        }),
      };

    case 'Main/Space/File::setProcessingStatus':
      logging.logDebug('mainReducer -> Main/Space/File::setProcessingStatus -> action.payload: ', action.payload);

      const { file, processing_status, processing_status_error } = action.payload;
      const filePath = typeof file === 'string' ? file : file.path;
      const spaceId = filePath.split('/')[0];
      const isFolder = filePath.indexOf('.') > -1 ? false : true; // do this check in case processing_status is set to undefined

      if (isFolder) {
        return {
          ...state,
          spaces: state.spaces.map((space: SpaceType) => {
            if (space.id === spaceId) {
              if (space.folders !== undefined) {
                space.folders = space.folders.map((spaceFolder: FileType) => {
                  if (spaceFolder.path === filePath) {
                    spaceFolder.processing_status = processing_status;
                    spaceFolder.processing_status_uts = getCurrentUts();
                    spaceFolder.processing_status_error = processing_status_error;
                    spaceFolder.last_modified = new Date();
                  }

                  return spaceFolder;
                });
              }
            }

            return space;
          }),
        };
      } else {
        return {
          ...state,
          files_uploaded: state.files_uploaded.map((uploaded_file: FileType) => {
            if (uploaded_file.path === filePath) {
              uploaded_file.processing_status = processing_status;
              uploaded_file.processing_status_uts = getCurrentUts();
              uploaded_file.processing_status_error = processing_status_error;
              uploaded_file.last_modified = new Date();
              if (!!processing_status && !processing_status_error && processing_status !== FileProcessingStatuses.Uploaded) {
                uploaded_file.processing = true;
              }
            }
            return uploaded_file;
          }),
          spaces: state.spaces.map((space: SpaceType) => {
            if (space.id === spaceId) {
              if (space.files !== undefined) {
                space.files = space.files.map((spaceFile: FileType) => {
                  if (spaceFile.path === filePath) {
                    spaceFile.processing_status = processing_status;
                    spaceFile.processing_status_uts = getCurrentUts();
                    spaceFile.processing_status_error = processing_status_error;
                    spaceFile.last_modified = new Date();
                    if (!!processing_status && !processing_status_error && processing_status !== FileProcessingStatuses.Uploaded) {
                      spaceFile.processing = true;
                    }
                  }
                  return spaceFile;
                });
              }
            }
            return space;
          }),
        };
      }

    case 'Main/Space/File::resetFileUpload':
      logging.logDebug('mainReducer -> Main/Space/File::resetFileUpload');

      return {
        ...state,
        files_uploaded: [],
      };

    case 'Main/Space/File::delete':
      logging.logDebug('mainReducer -> Main/Space/File::delete -> action.payload: ', action.payload);

      const fileToDelete = action.payload;

      return {
        ...state,
        spaces: state.spaces.map((space: SpaceType) => {
          if (space.id === fileToDelete.space_id) {
            if (space.files !== undefined) {
              space.files = space.files.filter((spaceFile: FileType) => {
                return spaceFile.path !== fileToDelete.path;
              });
            }
          }
          return space;
        }),
      };

    case 'Main::setLoading':
      logging.logDebug('mainReducer -> Main::setLoading -> action.payload: ', action.payload);

      const set_executing_entity_name = action.payload;

      return {
        ...state,
        loading: [...state.loading, set_executing_entity_name],
      };

    case 'Main::removeLoading':
      logging.logDebug('mainReducer -> Main::removeLoading -> action.payload: ', action.payload);

      const remove_executing_entity_name = action.payload;

      return {
        ...state,
        loading: state.loading.filter((executing_entity_name_in_loop) => executing_entity_name_in_loop !== remove_executing_entity_name),
      };

    case 'Main::addFatalError':
      logging.logDebug('mainReducer -> Main::addFatalError -> action.payload: ', action.payload);

      return {
        ...state,
        fatal_error: action.payload,
        loading: [],
      };

    default:
      return state;
  }
}
