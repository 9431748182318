import { useCallback, useEffect, useMemo, useState } from 'react';
import { BREAKPOINTS } from 'src/Util/MixedUtil';

/**
 * Check if we are in mobile view
 * @param position OPTIONAL! Override the value to check against. Default value is BREAKPOINTS.medium
 * @return Return true if we are in mobile view
 */
const useIsMobileView = (position?: number): boolean => {
  const [isMobileView, setIsMobileView] = useState(false);

  const breakpoint = useMemo(() => {
    if (typeof position === 'number') {
      return position;
    }

    return BREAKPOINTS.medium;
  }, [position]);

  /**
   * Check if we are in mobile view
   */
  const resize = useCallback(() => {
    if (window.innerWidth > breakpoint) {
      setIsMobileView(false);
    } else {
      setIsMobileView(true);
    }
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [position, resize]);

  return isMobileView;
};

export default useIsMobileView;
