import { IDefault } from '../../Util/InterfaceAndTypeUtil';
import LoadingBox from '../LoadingBox/LoadingBox';
//import styles from './ComponentLoader.module.scss';

interface IComponentLoader extends IDefault {}

function ComponentLoader(props: IComponentLoader) {
  return <LoadingBox className={props.className} text='Loading component...' ariaLabelSpinner='Loading component' />;
}

export default ComponentLoader;
