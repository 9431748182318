import slugify from 'slugify';
import {
  FileType,
  DiskFileType,
  PresignedUrlType,
  PresignedUrlResponseType,
  FileProcessingResponseType,
  StrKeyToBooleanMapType,
  StrKeyToStrMapType,
  RawFileType,
  BackendFileType,
  MainStateType,
  ITreeItem,
  IRegisterFilesForUploadRes,
  IFilesForUploadMap,
  ICheckUploadFileStatusRes,
  FileProcessingStatuses,
  OperationResultType,
} from './InterfaceAndTypeUtil';
import { spaceDomain } from './MixedUtil';
//import * as Time from "./TimeUtil"
import * as Data from './DataUtil';
import { fetch, fetchSpaces, logging } from 'w3-user-ui-component';
import { getCurrentUts } from './TimeUtil';

export const CREATEABLE_DYNAMIC_TEXT_BASED_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  ts: true,
  tsx: true,
  jsx: true,
  ejs: true,
  scss: true,
  vue: true,
  svelte: true,
  py: true,
  php: true,
  hbs: true,
  cs: true,
  cshtml: true,
  java: true,
  gradle: true,
  rs: true,
  toml: true,
};

export const CREATEABLE_TEXT_BASED_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  txt: true,
  html: true,
  htm: true,
  css: true,
  js: true,
  json: true,
  md: true,
  xml: true,
  svg: true,
  ...CREATEABLE_DYNAMIC_TEXT_BASED_FILE_EXTENSIONS,
};

export const EDITABLE_TEXT_BASED_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  ...CREATEABLE_TEXT_BASED_FILE_EXTENSIONS,
  babelrc: true,
  mjs: true,
};

export const NONEDITABLE_TEXT_BASED_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  pdf: true,
  env: true,
  h5: true,
  hdf5: true,
};

export const TEXT_BASED_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  ...CREATEABLE_TEXT_BASED_FILE_EXTENSIONS,
  ...NONEDITABLE_TEXT_BASED_FILE_EXTENSIONS,
};

export const IMAGE_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  // img
  bmp: true,
  gif: true,
  ico: true,
  jpg: true,
  jpeg: true,
  png: true,
};

export const VALID_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  ...TEXT_BASED_FILE_EXTENSIONS,
  ...IMAGE_FILE_EXTENSIONS,

  // font
  ttf: true,
  otf: true,
  woff: true,
  woff2: true,
  eot: true,
  sfnt: true,
};

export const MEDIA_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  mp3: true,
  mp4: true,
};

export const PAID_VALID_FILE_EXTENSIONS: StrKeyToBooleanMapType = {
  ...VALID_FILE_EXTENSIONS,
  ...MEDIA_FILE_EXTENSIONS,
};

export const DYNAMIC_MIME_TYPES: StrKeyToStrMapType = {
  tsx: 'text/prs.typescript',
  jsx: 'text/x-jsx',
  ejs: 'text/x-ejs',
  ts: 'text/prs.typescript',
  scss: 'text/prs.scss',
  vue: 'text/prs.vue',
  svelte: 'text/prs.svelte',
  py: 'text/x-python',
  php: 'application/x-httpd-php',
  hbs: 'text/x-handlebars-template',
  env: 'text/plain',
  babelrc: 'application/x-babelrc',
  h5: 'application/x-h5',
  hdf5: 'application/x-hdf5',
  java: 'text/x-java',
  cs: 'text/x-csharp',
  gradle: 'application/x-gradle',
  cshtml: 'text/x-xshtml',
  rs: 'text/x-rust',
  toml: 'text/x-toml',
};

export const MIME_TYPES: StrKeyToStrMapType = {
  ...DYNAMIC_MIME_TYPES,

  txt: 'text/plain',
  md: 'text/markdown',
  json: 'application/json',
  js: 'text/javascript',
  css: 'text/css',
  html: 'text/html',
  htm: 'text/html',
  xml: 'text/xml',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',

  // img
  bmp: 'image/bmp',
  gif: 'image/gif',
  ico: 'image/vnd.microsoft.icon',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',

  // font
  ttf: 'application/x-font-ttf',
  otf: 'application/x-font-opentype',
  woff: 'application/font-woff',
  woff2: 'application/font-woff2',
  eot: 'type=application/vnd.ms-fontobject',
  sfnt: 'application/font-sfnt',

  // media
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
};

/**
 * Length values for the file names
 */
export enum FileNameLength {
  /**
   * Maximum length of file names
   */
  MAX = 127,
  /**
   * Minimum length of file names
   */
  MIN = 1,
  /**
   * Max length including the file path
   */
  MAX_FILE_PATH_LENGTH = 960,
}

export const getValidFileExtensionsHumanReadableList = (): string => {
  return Object.keys(CREATEABLE_TEXT_BASED_FILE_EXTENSIONS).join(', ');
};

const getValidFileExtensionsFullListHumanReadableList = (): string => {
  return Object.keys(VALID_FILE_EXTENSIONS).join(', ');
};

const getValidPaidFileExtensionsFullListHumanReadableList = (): string => {
  return Object.keys(PAID_VALID_FILE_EXTENSIONS).join(', ');
};

/**
 * Check if item is a, or is located inside, a system folder.
 * @param item
 * @returns
 */
export const isInSystemFolder = (item: FileType | ITreeItem) => {
  if (item.path.toLowerCase().indexOf('w3s-dynamic-storage') > -1) {
    return true;
  }

  return false;
};

/**
 * Check if we can do any actions on this file.
 * @param file
 * @returns
 */
export const doesFileHaveAnyAction = (file: FileType | ITreeItem) => {
  if (isInSystemFolder(file)) {
    return false;
  }

  const isFolder = !!(file as FileType).is_folder || !!(file as ITreeItem).isFolder || false;

  if (isFolder) {
    // not a file
    return false;
  }

  let haveAction = !!Object.keys(EDITABLE_TEXT_BASED_FILE_EXTENSIONS).find((extension) => extension === file.extension);
  if (haveAction) {
    return true;
  }

  haveAction = !!Object.keys(IMAGE_FILE_EXTENSIONS).find((extension) => extension === file.extension);
  if (haveAction) {
    return true;
  }

  haveAction = !!Object.keys(MEDIA_FILE_EXTENSIONS).find((extension) => extension === file.extension);
  if (haveAction) {
    return true;
  }

  return false;
};

export const getProcessedMetaFromRawFile = (raw_file: RawFileType, active_path: string, processing: boolean, new_file_name?: string): FileType => {
  const filename = new_file_name ? new_file_name : slugify(raw_file.name);

  const disk_file: DiskFileType = {
    Key: `${active_path}${filename}`,
    LastModified: new Date(raw_file.lastModified).toString(),
    Size: raw_file.size,
  };

  let file = getProcessedMetaFromDiskFile(disk_file, active_path);

  file.raw_meta = raw_file;
  file.processing = processing;

  return file;
};

export const getEmptyFile = (file_path: string) => {
  let file = getProcessedMetaFromDiskFile({
    Key: file_path,
    LastModified: new Date().toString(),
    Size: 0,
  });

  file.raw_meta = new File([''], file_path);

  return file;
};

const _getMetaDefaults = (): FileType => {
  return {
    space_id: '',
    path: '', // abs path
    rel_path: '',
    dirs: [],
    base_name: '',
    base_name_lowercased: '',
    name: '',
    extension: '',
    mime_type: '',
    is_text_based: false,
    size: 0,
    last_modified: undefined,
    contents: '',
    raw_meta: undefined,
    processing: false,
    // pending_upload_uts: undefined,
  };
};

export const getProcessedMetaFromBackendFile = (be_file: BackendFileType | undefined): FileType => {
  let file = Data.getWithDefaults(be_file, _getMetaDefaults()) as FileType;

  if (file.pending_upload_uts !== undefined && file.pending_upload_uts !== null && file.pending_upload_uts) {
    file.processing = true;
    file.processing_status = FileProcessingStatuses.OnHold;
    file.processing_status_uts = getCurrentUts();
    file.deletable_while_processing = `${file.pending_upload_uts}_${file.processing_status_uts}`;
  }

  // @ts-ignore
  file.size = parseInt(file.size);

  // @ts-ignore
  file.last_modified = new Date(file.last_modified);

  return file;
};

export const getProcessedMetaFromFilePath = (file_path: string | undefined, space_id?: string): FileType => {
  let file: FileType = _getMetaDefaults();

  if (file_path === undefined) {
    return file;
  }

  const file_path_chunks = file_path.split('/');

  if (file_path_chunks.length) {
    file.space_id = space_id ? space_id : file_path_chunks[0];

    // Modify path to reflect the OPEN version of the path
    file.path = [file.space_id, ...file_path_chunks.slice(1, file_path_chunks.length)].join('/');

    file.base_name = file_path_chunks[file_path_chunks.length - 1];

    file.dirs = file_path_chunks.slice(0, file_path_chunks.length - 1);

    file.rel_path = file_path_chunks.slice(1, file_path_chunks.length).join('/');
  } else {
    file.path = file_path;

    file.base_name = file_path_chunks[0];

    file.rel_path = file.path;
  }

  file.base_name_lowercased = file.base_name.toLowerCase();

  const file_base_name_chunks = file.base_name.split('.');

  file.extension = file_base_name_chunks[file_base_name_chunks.length - 1].toLowerCase();

  file.mime_type = MIME_TYPES[file.extension];

  file.name = file_base_name_chunks.splice(0, file_base_name_chunks.length - 1).join('.');

  file.is_text_based = TEXT_BASED_FILE_EXTENSIONS[file.extension] === true;

  return file;
};

// TODO: (high) deprecate this temporary solution when we will retrieve the processed data from db
export const getProcessedMetaFromDiskFile = (disk_file: DiskFileType | undefined, space_id?: string): FileType => {
  if (disk_file === undefined) {
    return _getMetaDefaults();
  }

  let file: FileType = getProcessedMetaFromFilePath(disk_file.Key);

  file.size = disk_file.Size; // in bytes, TODO: (high) investigate

  file.last_modified = new Date(disk_file.LastModified);

  return file;
};

export const getExternalLink = (file: FileType, bypass_browser_cache?: boolean | undefined, custom_domain?: string) => {
  if (bypass_browser_cache === undefined) {
    bypass_browser_cache = true;
  }

  const domain = spaceDomain();
  const newDate = new Date().getTime().toString();
  const cache = newDate.substring(newDate.length - 8);
  const query_str = bypass_browser_cache ? '?bypass-cache=' + cache : '';

  if (custom_domain !== undefined) {
    return `https://${custom_domain}/${file.rel_path}${query_str}`;
  }

  return `https://${file.space_id}.${domain}/${file.rel_path}${query_str}`;
};

export const getPreviewLink = (file: FileType) => {
  const domain = spaceDomain();

  // TODO: (mid) add the file access token query param when we will improve the file related functinality
  return `https://preview.${domain}/?path=${encodeURIComponent(file.path)}`;
};

export const getFormDataForPresignedUrl = (url: PresignedUrlType) => {
  let formData = new FormData();

  // Append signing details to form data
  formData.append('key', url.fields.key);
  formData.append('AWSAccessKeyId', url.fields.AWSAccessKeyId);
  formData.append('x-amz-security-token', url.fields['x-amz-security-token']);
  formData.append('policy', url.fields.policy);
  formData.append('signature', url.fields.signature);

  return formData;
};

export const getBackendFriendlyFileName = (fileBaseName: string): string => {
  let output = fileBaseName
    .replace(/(\.)+/g, '.') // multiple consecutive dots to single dot
    .replace(/\s/g, '_') // white spaces, new lines and tabs to underscore
    .replace(/([\/|\\])+/g, '_') // slashes to underscore
    .replace(/([\(|\)])+/g, '_') // parantheses to underscore
    .replace(/(_)+/g, '_'); // multiple consecutive underscores to single underscore

  return slugify(output);
};

/**
 *
 * @param file_base_name
 * @param existing_files
 * @param file_base_path Optional! The path of the file we are validating (without file name in it). Include if we include existing_files.
 * @returns
 */
export const validateFileName = (
  file_base_name: string,
  existing_files?: FileType[],
  file_base_path?: string,
  plan?: string,
  mimeType?: string,
): string | undefined => {
  const parts = file_base_name.split('.');

  if (parts.length < 2) {
    return `File must have a valid extension. Valid extensions are "${getValidFileExtensionsFullListHumanReadableList()}"`;
  }

  const extension = parts[parts.length - 1].toLowerCase();

  if (plan !== 'free') {
    if (mimeType === MIME_TYPES['mp4'] && extension !== 'mp4') {
      return `Video need to have the mp4 extension.`;
    } else if (mimeType === MIME_TYPES['mp3'] && extension !== 'mp3') {
      return `Audio need to have the mp3 extension.`;
    } else if (extension === 'mp3' && mimeType && mimeType !== MIME_TYPES[extension]) {
      return `This is not a valid audio file.`;
    } else if (extension === 'mp4' && mimeType && mimeType !== MIME_TYPES[extension]) {
      return `This is not a valid video file.`;
    } else if (PAID_VALID_FILE_EXTENSIONS[extension] !== true) {
      return `File extension "${extension}" is not accepted. Accepted file extensions are "${getValidPaidFileExtensionsFullListHumanReadableList()}"`;
    }
  } else {
    if (mimeType === MIME_TYPES['mp4'] || mimeType === MIME_TYPES['mp3']) {
      return `This file type is not allowed to add or edit on this subscription. Upgrade in order to rename existing or upload new media files.`;
    } else if (VALID_FILE_EXTENSIONS[extension] !== true) {
      return `File extension "${extension}" is not accepted. Accepted text based file extensions are "${getValidFileExtensionsHumanReadableList()}"`;
    }
  }

  if (plan === 'free' || plan === 'pro') {
    if (!!CREATEABLE_DYNAMIC_TEXT_BASED_FILE_EXTENSIONS[extension] || !!DYNAMIC_MIME_TYPES[extension]) {
      return `This file type is not allowed to add or edit on this subscription. Upgrade in order to rename existing or upload a new file.`;
    }
  }

  /* if (existing_files?.length && existing_files.some((file: FileType) => file.base_name === file_base_name) {
        return 'A file with that name already exists'
    } */

  if (existing_files?.length) {
    if (!!file_base_path) {
      // check filename within the path the file is located.
      const newFilePath = file_base_path + file_base_name;
      if (existing_files.some((file: FileType) => file.path === newFilePath)) {
        return 'A file with that name already exists';
      }
    } else {
      // old code -> backup
      if (existing_files.some((file: FileType) => file.base_name === file_base_name)) {
        return 'A file with that name already exists';
      }
    }
  }

  if (file_base_path) {
    let splitPath = file_base_path.split('/');
    let newPath = '';

    if (splitPath.length > 1) {
      splitPath.shift();
      newPath = splitPath.join('/');
      const pathLength = (newPath + file_base_name).length;

      if (pathLength > FileNameLength.MAX_FILE_PATH_LENGTH) {
        const splitName = file_base_name.split('.');
        const fileExtensionLength = splitName[splitName.length - 1].length + 1; // add 1 to include .

        const howTooMuch = pathLength - FileNameLength.MAX_FILE_PATH_LENGTH;
        const maxLengthOfName = file_base_name.length - howTooMuch - fileExtensionLength;

        return `File path and name exceed max length due to lengthy folder and/or file name. Max length of filename can be ${maxLengthOfName} characters.`;
      }
    }
  }

  if (file_base_name.indexOf(' ') !== -1) {
    // other nasty typed of white space will fail in the checks below
    return 'Filename cannot contain white space " "';
  }

  if (file_base_name.indexOf('..') !== -1) {
    return 'Filename cannot contain two neighbour ".."';
  }

  if (!/^(\+)?[a-zA-Z0-9._-]*$/.test(file_base_name)) {
    return 'Invalid file name';
  }

  return undefined;
};

export const generatePresignedUrlForUpload = async (file: FileType, main_state: MainStateType): Promise<PresignedUrlResponseType> => {
  const reqRes = await fetchSpaces<PresignedUrlResponseType['data']>({
    url: '/signedurl',
    method: 'POST',
    data: {
      type: 'file_upload',
      file_path: file.path,
      _out_of_quota: typeof main_state !== 'undefined' ? main_state.usage_status._out_of_quota : null,
    },
  });

  logging.logDebug('FileUtil -> generatePresignedUrlForUpload -> reqRes: ', reqRes);

  return reqRes;
};

export const registerFilesForUpload = async ({
  warmup = false,
  uploadGroupId = '',
  spaceId,
  files,
  mainState,
}: // _presignedUrls,
// _depthLevel = 0,
{
  warmup?: boolean;
  uploadGroupId?: string;
  spaceId: string;
  files: IFilesForUploadMap;
  mainState: MainStateType;
  // _presignedUrls?: IPresignedUrlsMap,
  // _depthLevel?: number,
}): Promise<IRegisterFilesForUploadRes> => {
  const reqRes = await fetchSpaces<IRegisterFilesForUploadRes['data']>({
    url: '/register-files-for-upload',
    method: 'POST',
    data: {
      warmup,
      uploadGroupId,
      spaceId,
      files,
      // version,
      _out_of_quota: typeof mainState !== 'undefined' ? mainState.usage_status._out_of_quota : null,
    },
  });

  logging.logDebug('FileUtil -> registerFilesForUpload -> reqRes: ', reqRes);

  // if (
  //   _depthLevel
  //   && _presignedUrls !== undefined
  //   && Object.keys(_presignedUrls).length
  // ) {
  //   reqRes.data.presignedUrls = { ...(reqRes.data.presignedUrls || {}), ..._presignedUrls };
  // }

  // if (
  //   reqRes.data.asyncProcessingFiles !== undefined
  //   && Object.keys(reqRes.data.asyncProcessingFiles).length
  // ) {
  //   if (_depthLevel < 3) { // try again
  //     return await registerFilesForUpload({
  //       spaceId,
  //       files: reqRes.data.asyncProcessingFiles,
  //       version: '2',
  //       mainState,
  //       _presignedUrls: reqRes.data.presignedUrls,
  //       _depthLevel: _depthLevel + 1,
  //     });
  //   } else if (reqRes.error.code === '0') {
  //     reqRes.error = {
  //       code: 'FEWAUTRATFFU',
  //       description: 'We are unable to register all the files for upload',
  //       meta: {
  //         asyncProcessingFiles: reqRes.data.asyncProcessingFiles,
  //       }
  //     };
  //   }
  // }

  return reqRes;
};

export const checkUploadFileStatus = async ({
  uploadGroupId,
  spaceId,
  files,
}: {
  uploadGroupId: string;
  spaceId: string;
  files: string[];
}): Promise<ICheckUploadFileStatusRes> => {
  const reqRes = await fetchSpaces<ICheckUploadFileStatusRes['data']>({
    url: '/check-upload-file-status',
    method: 'POST',
    data: {
      uploadGroupId,
      spaceId,
      files,
    },
  });

  logging.logDebug('FileUtil -> checkUploadFileStatus -> reqRes: ', reqRes);

  return reqRes;
};

export const triggerFilesUploadCompleted = async ({
  uploadGroupId,
  spaceId,
}: {
  uploadGroupId: string;
  spaceId: string;
}): Promise<OperationResultType> => {
  const reqRes = await fetchSpaces<OperationResultType>({
    url: '/files-upload-completed',
    method: 'POST',
    data: {
      upload_group_id: uploadGroupId,
      space_id: spaceId,
    },
  });

  logging.logDebug('FileUtil -> triggerFilesUploadCompleted -> reqRes: ', reqRes);

  return reqRes;
};

export const uploadFile = async (url: PresignedUrlType, file: FileType, toastId?: string, onUploadProgress?: (progressEvent: any) => void) => {
  logging.logDebug('uploadFile -> url, file: ', url, file);

  if (!file || !file.raw_meta) {
    return;
  }

  let formData = getFormDataForPresignedUrl(url);

  formData.append('file', file.raw_meta, file.base_name);

  const reqRes = await fetch<any>({
    url: url.url,
    method: 'POST',
    data: formData,
    headers: {
      'content-type': 'multipart/form-data',
    },
    timeout: 90000,
    // cache: false,
    authenticated: false,
    onUploadProgress,
  });

  logging.logDebug('FileUtil -> uploadFile -> reqRes: ', reqRes);

  return reqRes;
};

export const processFile = async (file: FileType, main_state: MainStateType): Promise<FileProcessingResponseType> => {
  return await fetchSpaces<FileProcessingResponseType['data']>({
    url: '/processfiles',
    method: 'POST',
    data: {
      path: file.path,
      _out_of_quota: typeof main_state !== 'undefined' ? main_state.usage_status._out_of_quota : null,
    },
  });
};

export const getUniqueFilename = (filename_start: string, current_file_paths: string[], active_path: string): string => {
  let filename = filename_start;
  for (let i = 0; i <= 100; i++) {
    // eslint-disable-next-line no-loop-func
    const duplicates = current_file_paths.filter((current_path: string) => {
      return current_path === active_path + filename;
    });

    if (duplicates.length === 0) {
      return filename;
    }

    let name_parts = filename_start.split('.');
    if (i === 0) {
      filename = name_parts.slice(0, name_parts.length - 1).join('.') + '-copy.' + name_parts[name_parts.length - 1];
    } else {
      filename = name_parts.slice(0, name_parts.length - 1).join('.') + '-copy-' + i + '.' + name_parts[name_parts.length - 1];
    }
  }
  return filename;
};

export const getSignedFilePreviewUrlResponse = async (file: FileType, main_state: MainStateType) => {
  return await fetchSpaces<any>({
    url: '/signedurl',
    method: 'POST',
    data: {
      type: 'file_preview',
      file_path: file.path,
      _out_of_quota: typeof main_state !== 'undefined' ? main_state.usage_status._out_of_quota : null,
    },
  });
};

export const compareFileTypesByModified = (a: FileType, b: FileType) => {
  if (a.last_modified === undefined && b.last_modified === undefined) {
    return 0; // equal
  }

  if (a.last_modified === undefined) {
    return 1; // b must be defined, and thus bigger than a
  }

  if (b.last_modified === undefined) {
    return -1; // a must be defined, and thus bigger than b
  }

  if (a.last_modified < b.last_modified) {
    return 1; // a smaller than b
  }

  if (a.last_modified > b.last_modified) {
    return -1; // a larger than b
  }
  // a and b are equal
  return 0;
};

const MaxFileNameLength = 25;
const FileNameLengthFirstPart = 16;

/**
 * Truncate the name to be displayed if it is too long
 * @param name
 * @param includeFolderPath Optional! Set to true if the name includes the whole path with folders.
 * @returns
 */
export const getValidLengthFileName = (name: string, includeFolderPath: boolean = false): string => {
  let returnValue = '';
  let checkName = name;
  let nameSplit: string[] = [];

  if (includeFolderPath) {
    nameSplit = name.split('/');
    if (nameSplit.length > 1) {
      checkName = nameSplit[nameSplit.length - 1];
      nameSplit.pop();
    } else {
      includeFolderPath = false;
    }
  }

  if (checkName.length > MaxFileNameLength) {
    const lastIndex = checkName.lastIndexOf('.');
    const nameArray = [];
    nameArray[0] = checkName.substr(0, lastIndex);
    nameArray[1] = checkName.substr(lastIndex + 1);

    //const nameArray = checkName.split('.');
    const nameLength = nameArray[0].length;

    if (nameLength > MaxFileNameLength - 4) {
      returnValue = nameArray[0].substring(0, FileNameLengthFirstPart) + '...' + nameArray[0].substr(nameLength - 3, nameLength) + '.' + nameArray[1];
    } else {
      returnValue =
        nameArray[0].substring(0, FileNameLengthFirstPart - 3) + '...' + nameArray[0].substr(nameLength - 3, nameLength) + '.' + nameArray[1];
    }
  } else {
    returnValue = checkName;
  }

  if (includeFolderPath) {
    returnValue = `${nameSplit.join('/')}/${returnValue}`;
  }

  return returnValue;
};

/**
 * Test a text if any characters are uppercase
 * @param text
 * @returns
 */
export const hasAnyUppercase = (text: string) => {
  const isUpperCase = (string: string) => /^[A-Z]*$/.test(string);
  let hasUpperCase = false;
  let index = 0;

  do {
    hasUpperCase = isUpperCase(text.charAt(index));
    index += 1;
  } while (!hasUpperCase && index < text.length);

  return hasUpperCase;
};

export const getFileExtensionFromName = (fileName: string): string => {
  if (fileName.indexOf('.') === -1) {
    return '';
  }

  const fileNameParts = fileName.split('.');

  return fileNameParts[fileNameParts.length - 1].toLowerCase();
};
