import React, { useMemo } from 'react';
import { ProgressBar, Toast } from 'react-bootstrap';
import { Spinner } from 'w3-user-ui-component';
import { UserNotificationType } from '../../Util/InterfaceAndTypeUtil';
import { RootStateType } from '../../Redux';
import { useDispatch, useSelector } from 'react-redux';
import { dismissNotification } from '../../Redux/Reducer/NotificationReducer';
import { SuccessIcon } from './icons/Success';
import { ErrorIcon } from './icons/Error';
import { InfoIcon } from './icons/Info';
import UploadSuccess from './icons/UploadSuccess';

import './NotificationHandler.scss';
import { isActivationKeyPressed } from 'src/Util/MixedUtil';

function NotificationHandler() {
  const dispatch = useDispatch();
  const rawNotifications = useSelector((state: RootStateType) => state.notification.notifications);
  // const rawState = useSelector((state: RootStateType) => state)

  const notifications = useMemo(() => {
    const notificationsToShow = rawNotifications.filter((notification: UserNotificationType) => !notification.dismissed);
    const uploadNotifications = notificationsToShow.filter((notification: UserNotificationType) => notification.showProgressBar);

    let groupedNotifications = [];
    let uploadsAdded = false;

    for (const notification of notificationsToShow) {
      if (!notification.showProgressBar) {
        groupedNotifications.push(notification);
      } else if (!uploadsAdded) {
        groupedNotifications.push(uploadNotifications);
        uploadsAdded = true;
      }
    }

    return groupedNotifications;
  }, [rawNotifications]);

  function getMostSignificantNotification(notifications: UserNotificationType[]) {
    const inProgress = notifications.filter(
      (notification: UserNotificationType) => notification.showProgressBar && notification.progressBarLevel !== 100,
    );
    if (inProgress.length > 0) {
      return inProgress[0];
    }

    const error = notifications.filter((notification: UserNotificationType) => notification.type === 'error');
    if (error.length > 0) {
      return error[0];
    }

    return notifications[0];
  }

  function notificationIcon(notification: UserNotificationType) {
    if (notification.type === 'error') {
      return <ErrorIcon />;
    } else if (notification.type === 'info') {
      return <InfoIcon />;
    }

    if (notification.type === 'spinner' || (notification.showProgressBar && notification.progressBarLevel !== 100)) {
      return <Spinner size={Spinner.utils.size.md} variant={Spinner.utils.variants.Dark} className='-spinner' />;
    }

    return <SuccessIcon />;
  }

  function onCloseAll() {
    notifications.forEach((notificationGroup: UserNotificationType | UserNotificationType[]) => {
      if (Array.isArray(notificationGroup)) {
        notificationGroup.forEach((notif) => {
          if (!notif.pinned) {
            dispatch(dismissNotification(notif));
          }
        });
      } else if (!notificationGroup.pinned) {
        dispatch(dismissNotification(notificationGroup));
      }
    });
  }

  const onCloseAllKeyDown = (event: React.KeyboardEvent) => {
    if (isActivationKeyPressed(event)) {
      event.stopPropagation();
      event.preventDefault();

      onCloseAll();
    }
  };

  return (
    <div className='notification-wrapper'>
      {notifications.length > 1 && (
        <div className='notification-close-all'>
          <div onClick={onCloseAll} onKeyDown={onCloseAllKeyDown} tabIndex={0} aria-label='Close all closeable notifications'>
            Close all
          </div>
        </div>
      )}
      {notifications.map((notificationGroup: UserNotificationType | UserNotificationType[]) => {
        let notification: UserNotificationType;
        let notificationList: UserNotificationType[];
        if (Array.isArray(notificationGroup)) {
          notification = getMostSignificantNotification(notificationGroup);
          notificationList = notificationGroup;
        } else {
          notification = notificationGroup;
          notificationList = [notificationGroup];
        }

        return (
          <Toast
            show={!notification.dismissed}
            onClose={() => dispatch(dismissNotification(notification))}
            delay={notification.delay ?? 5000}
            autohide={!(notification.autohide === false || notification.type === 'error')}
            key={notification.id}
            role='alert'
            aria-live='assertive'
            aria-atomic='true'
          >
            <Toast.Header closeButton={notification.closeButton ?? true}>
              <span className='-icon'>{notificationIcon(notification)}</span>

              <span className='-text'>{notification.header}</span>
            </Toast.Header>

            <Toast.Body>
              {notificationList.map((notification: UserNotificationType, index: number) => {
                return (
                  <div className='single' key={`${notification.id}_${index}`}>
                    {notification.body}

                    {notification.type !== 'error' && notification.progressBarLevel !== 100 ? (
                      <div className='filename'>{notification.filename}</div>
                    ) : (
                      ``
                    )}

                    {notification.progressBarLevel === 100 ? (
                      <div className='upload-success-icon'>
                        <UploadSuccess />
                      </div>
                    ) : (
                      ``
                    )}

                    {notification?.showProgressBar && (
                      <ProgressBar
                        now={notification.progressBarLevel}
                        className={notification.progressBarLevel === 100 ? 'progress-sucess' : ''}
                        variant={notification.progressBarLevel === 100 ? 'success' : 'info'}
                        role='progressbar'
                        aria-valuemin={0}
                        aria-valuemax={100}
                        aria-valuenow={notification.progressBarLevel}
                        aria-label={`Progress ${notification.header}`}
                      />
                    )}
                  </div>
                );
              })}
            </Toast.Body>
          </Toast>
        );
      })}
    </div>
  );
}

export default NotificationHandler;
