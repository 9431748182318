/**
 * Return a string representation of the number with space within every 3 digit. E.g. 1 000 000
 * @param number 
 * @returns 
 */
export const numberWithSpaces = (number: string | number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const getHumanReadableSizeFromBytes = (bytes: number | string, intParserRadix?: number, decimals: number = 2): string => {
    // @ts-ignore
    bytes = parseInt(bytes, intParserRadix)

    if (bytes < 1024) {
        return bytes + ' B'
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(decimals) + ' kB'
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / 1024 / 1024).toFixed(decimals) + ' MB'
    }

    return (bytes / 1024 / 1024 / 1024).toFixed(decimals) + ' GB'
}

export const getHumanReadableSizeFromKiloBytes = (kiloBytes: number | string, decimals: number = 2, spaceEveryThreeDigit: boolean = false): string => {

    if (typeof kiloBytes === typeof "string") {
        kiloBytes = parseFloat(kiloBytes as string)
    }

    kiloBytes = kiloBytes as number

    if (kiloBytes < 1024) {
        return (spaceEveryThreeDigit ? numberWithSpaces(kiloBytes.toFixed(decimals)) : kiloBytes.toFixed(decimals)) + ' kB'
    } else if (kiloBytes < 1024 * 1024) {
        return (spaceEveryThreeDigit ? numberWithSpaces((kiloBytes / 1024).toFixed(decimals)) : (kiloBytes / 1024).toFixed(decimals)) + ' MB'
    }

    return (spaceEveryThreeDigit ? numberWithSpaces((kiloBytes / 1024 / 1024).toFixed(decimals)) : (kiloBytes / 1024 / 1024).toFixed(decimals)) + ' GB'
}

export const getHumanReadableSizeFromMegaBytes = (megaBytes: number | string, decimals: number = 2): string => {

    if (typeof megaBytes === typeof "string") {
        megaBytes = parseFloat(megaBytes as string)
    }

    megaBytes = megaBytes as number

    if (megaBytes < 1024) {
        return megaBytes.toFixed(decimals) + ' MB'
    }

    return (megaBytes / 1024).toFixed(decimals) + ' GB'
}
