import { ReactChild } from 'react';
import { IDefault } from '../../../Util/InterfaceAndTypeUtil';
import styles from './TopHeaderGroup.module.scss';

type ChildrenProps = {
  left?: ReactChild;
  center?: ReactChild;
  right?: ReactChild;
};

interface ITopHeaderGroup extends IDefault {
  children: ReactChild | ChildrenProps;
  classNameLeft?: string;
  classNameMiddle?: string;
  classNameRight?: string;
}

const isObject = <T extends Object>(value: any): value is T => typeof value === 'object' && typeof value !== 'function' && value !== undefined;
const isNamedSlots = (children: any): children is ChildrenProps => isObject(children);

function TopHeaderGroup(props: ITopHeaderGroup) {
  return (
    <div className={styles.header + (!!props.className ? ' ' + props.className : '')}>
      {isNamedSlots(props.children) ? (
        <div className={styles.header_top_row}>
          <div className={`${styles.header_buttons_left}${props.classNameLeft ? ` ${props.classNameLeft}`:''}`}>{!!props.children && !!props.children.left && props.children.left}</div>
          <div className={`${styles.header_buttons_middle}${props.classNameMiddle ? ` ${props.classNameMiddle}`:''}`}>{!!props.children && !!props.children.center && props.children.center}</div>
          <div className={`${styles.header_buttons_right}${props.classNameRight ? ` ${props.classNameRight}`:''}`}>{!!props.children && !!props.children.right && props.children.right}</div>
        </div>
      ) : (
        props.children
      )}
    </div>
  );
}

export default TopHeaderGroup;
