import { typedAction } from '../../Util/InterfaceAndTypeUtil';
import {
    Dispatch,
    AnyAction,
} from 'redux';
import { auth } from 'w3-user-ui-component';
import { logging } from 'w3-user-ui-component';

interface AuthStateType {
    showLogin: boolean,
    loggedIn: boolean,
}

export const authInitialState: AuthStateType = {
    showLogin: true,
    loggedIn: false,
}

export const logOut = () => {
    return async (dispatch: Dispatch<AnyAction>) => {
        dispatch(setLoggedIn(false));

        auth.logOutViaRedirect({
          context: 'logOut -> Spaces auth reducer log out',
          originUrl: window.location.href,
        });
    }
}

export const setLoggedIn = (loggedIn: boolean) => {
    return typedAction('Auth::setLoggedIn', loggedIn);
}

export const refreshLoginStatus = (context: string) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        const res = await auth.processUserSession({
          context: `AuthReducer -> refreshLoginStatus -> context: ${context}`,
          preferDethrottleCache: false,
        });
        logging.logDebug('AuthReducer -> refreshLoginStatus -> processUserSession -> res: ', res);

        dispatch(setLoggedIn(res.error.code === '0'));
    }
}

type AuthAction = ReturnType<typeof setLoggedIn>

export function authReducer(
    state = authInitialState,
    action: AuthAction,
): AuthStateType {
    switch (action.type) {
        case 'Auth::setLoggedIn':
            logging.logDebug('authReducer -> Auth::setLoggedIn -> action.payload: ', action.payload);

            return {
                ...state,
                loggedIn: action.payload
            };

        default:
            return state;
    }
}
