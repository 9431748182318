import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-bootstrap-icons';
import { IDefault } from 'src/Util/InterfaceAndTypeUtil';
import MobileBottomPanelElement from './MobileBottomPanelElement/MobileBottomPanelElement';
import PopoverMenu from 'src/Component/PopoverMenu/PopoverMenu';

import styles from './MobileBottomPanel.module.scss';

interface IMobileBottomPanel extends IDefault {
  /**
   * True if component is disabled
   */
  disabled?: boolean;
  /**
   * True if last element should be a menu
   */
  displayMenu?: boolean;
  /**
   * Indicates if the menu is open or not
   */
  menuOpen?: boolean;
  /**
   * The children to be displayed in the menu. Wrap each child with the MobileMenuChild component
   */
  menuChildren?: React.ReactElement;
  /**
   * WHen clicking on the menu button to open/close the menu
   * @param isOpen True if menu was opened
   */
  onClickMenuTrigger?: (isOpen: boolean) => void;
  /**
   * Triggers when the menu is removed
   */
  onMenuDisappear?: () => void;
  /**
   * Triggers when it is clicked outside the menu
   */
  onMenuOutsideClick?: () => void;
}

const MobileBottomPanel = ({
  disabled = false,
  displayMenu = false,
  id,
  className,
  children,
  menuChildren,
  menuOpen,
  onClickMenuTrigger,
  onMenuDisappear,
  onMenuOutsideClick,
}: React.PropsWithChildren<IMobileBottomPanel>) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const clickMenu = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
      onClickMenuTrigger && onClickMenuTrigger(!isOpen);
    }
  };

  const onMenuDisappeared = () => {
    setIsOpen(false);
    onMenuDisappear && onMenuDisappear();
  };

  const onMenuOutsideClicked = () => {
    setIsOpen(false);

    if (onMenuOutsideClick) {
      onMenuOutsideClick();
    } else {
      onMenuDisappeared();
    }
  };

  useEffect(() => {
    setIsOpen(menuOpen || false);
  }, [menuOpen]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`} role='navigation'>
      {children}

      {displayMenu && (
        <PopoverMenu
          triggerElm={
            <MobileBottomPanelElement selected={false} disabled={isDisabled} onClick={clickMenu} ariaLabel='More'>
              <ThreeDots /> More
            </MobileBottomPanelElement>
          }
          placement='top-end'
          disabled={isDisabled}
          onDisappear={onMenuDisappeared}
          onOutsideClick={onMenuOutsideClicked}
        >
          {menuChildren || <div />}
        </PopoverMenu>
      )}
    </div>
  );
};

export default MobileBottomPanel;
