import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { setDefaultFetchConfig, Terms, auth, TopNavBar } from 'w3-user-ui-component';
import 'w3-user-ui-component/dist/index.css';

import NotificationHandler from '../NotificationHandler/NotificationHandler';
import ConnectivityCheck from '../Status/Connectivity';
// import IdleCheck from '../Status/Idle';
import { RootStateType } from '../../Redux';
import { fetchMainBulkData, setFeatureFlags } from '../../Redux/Reducer/MainReducer';
import { refreshAuthStatus } from '../../Redux/Action/AuthAction';
import { fetchDomainOrders } from '../../Redux/Action/DomainsAction';
import FatalError from '../FatalError/FatalError';
import QuotaInfoBar from '../QuotaInfoBar/QuotaInfoBar';
import ModalHandler from '../ModalHandler/ModalHandler';
import LoadingBox from '../LoadingBox/LoadingBox';
import PrivateRoute from './Router/PrivateRoute';
import ComponentLoader from '../ComponentLoader/ComponentLoader';
import { setLauncherPosition } from 'src/Util/MixedUtil';
import { fetchAiPrices } from 'src/Redux/Action/AiAction';
import { FeatureFlags, checkAIAgainstSubscription } from 'src/Util/SpaceUtil';

import './Base.scss';
import CodeEditorWrapper from '../CodeEditor/CodeEditorWrapper';

const CreateSpace = React.lazy(() => import('../CreateSpace/CreateSpace'));
const NewUserLanding = React.lazy(() => import('../NewUserLanding/NewUserLanding'));
const Usage = React.lazy(() => import('../Usage/Usage'));
const DomainRegistrationForm = React.lazy(() => import('../DomainRegistrationForm/DomainRegistrationForm'));
const DomainOrders = React.lazy(() => import('../DomainOrders/DomainOrders'));
const DomainSearch = React.lazy(() => import('../DomainSearch/DomainSearch'));
const DomainPromo = React.lazy(async () => {
  await import('../DomainRegistrationForm/DomainRegistrationForm');
  await import('../DomainOrders/DomainOrders');
  await import('../DomainSearch/DomainSearch');
  return import('../DomainPromo/DomainPromo');
});
const DomainCheckTransferability = React.lazy(() => import('../DomainCheckTransferability/DomainCheckTransferability'));
const GitHubAuthorize = React.lazy(() => import('../GitHub/GitHubAuthorize/GitHubAuthorize'));

interface IBase {
  version: string;
}

const Base = ({ version }: IBase) => {
  const dispatch = useDispatch();
  const main_state = useSelector((state: RootStateType) => state.main);
  const fatal_error = useSelector((state: RootStateType) => state.main.fatal_error);
  const loggedIn = useSelector((state: RootStateType) => state.auth.loggedIn);
  const [userSessionSoftLookupDone, setUserSessionSoftLookupDone] = useState(false);
  const display_footer = useSelector((state: RootStateType) => state.main.display_footer);
  const display_header = useSelector((state: RootStateType) => state.main.display_header);

  const [subscriptionPlan, setSubscriptionPlan] = useState<string>('free');

  const displayBanner = useMemo(() => {
    return FeatureFlags.topBanner && loggedIn;
  }, [loggedIn]);

  const initialLoad = useCallback(() => {
    setDefaultFetchConfig({
      refreshTokenOnFailedCognitoAuth: true,
      processUserSessionRef: auth.processUserSession,
      changeStatusCodeInUserSessionCookiesRef: auth.changeStatusCodeInUserSessionCookies,
    });

    if (window.location.pathname === '/createspace') {
      dispatch(refreshAuthStatus('Base - initialLoad - createspace', true));
    } else {
      dispatch(refreshAuthStatus('Base - initialLoad'));
    }
  }, [dispatch]);

  useEffect(() => {
    setSubscriptionPlan(main_state.subscription_plan.name);
    checkAIAgainstSubscription(main_state.subscription_plan.name || '');
  }, [main_state.subscription_plan.name]);

  useEffect(() => {
    setLauncherPosition();
    window.addEventListener('resize', setLauncherPosition);
    window.addEventListener('orientationchange', setLauncherPosition);
    initialLoad();

    return () => {
      window.removeEventListener('resize', setLauncherPosition);
      window.removeEventListener('orientationchange', setLauncherPosition);
    };
  }, [initialLoad]);

  useEffect(() => {
    if (loggedIn) {
      auth
        .getUserInfoFromCookies()
        .then((res: any) => {
          if (res) {
            setSubscriptionPlan(res.plan);
            dispatch(
              setFeatureFlags({
                noUpsell: res.noUpsell || false,
                adFree: res.adFree ?? false,
              }),
            );
            setUserSessionSoftLookupDone(true);
          } else {
            setUserSessionSoftLookupDone(true);
          }
        })
        .catch((error) => {
          setUserSessionSoftLookupDone(true);
        });

      dispatch(fetchMainBulkData());
      dispatch(fetchDomainOrders());
      if (FeatureFlags.ai && FeatureFlags.aiEstimateCost) {
        dispatch(fetchAiPrices());
      }
    }
  }, [dispatch, loggedIn]);

  return (
    <>
      {display_header && (
        <TopNavBar
          zIndex={999999}
          displayBanner={displayBanner}
          userSessionProcessed={userSessionSoftLookupDone}
          userSessionMeta={{
            loggedIn,
            subscriptionPlan,
            featureFlags: main_state.featureFlags,
          }}
        />
      )}

      {!fatal_error && loggedIn && main_state.fully_initialized && <QuotaInfoBar />}

      <Suspense fallback={<ComponentLoader />}>
        <Router>
          <Container fluid className={displayBanner ? ` display-banner` : ''} role='main'>
            <Switch>
              {fatal_error && <FatalError message={fatal_error} />}

              {!fatal_error && (
                <Route exact path='/createspace'>
                  <CreateSpace resetSpace={false} backToSpaces={false} />
                </Route>
              )}

              {loggedIn && (
                <>
                  {!fatal_error && (
                    <>
                      <Switch>
                        <Route exact path='/createspace/stepone'>
                          {main_state.initialized && <CreateSpace resetSpace={false} backToSpaces />}
                        </Route>

                        <Route exact path='/resetspace'>
                          <CreateSpace resetSpace={true} />
                        </Route>

                        <PrivateRoute path='/space/:spaceId/editor'>
                          <CodeEditorWrapper />
                        </PrivateRoute>

                        <PrivateRoute path='/domains/start/:spaceId?'>
                          <DomainPromo />
                        </PrivateRoute>

                        <PrivateRoute path='/domains/search/:spaceId'>
                          <DomainSearch />
                        </PrivateRoute>

                        <PrivateRoute path='/domains/checkTransferability/:spaceId'>
                          <DomainCheckTransferability />
                        </PrivateRoute>

                        <PrivateRoute path='/domains/register/:spaceId/:domain/:isTransfer'>
                          <DomainRegistrationForm />
                        </PrivateRoute>

                        <PrivateRoute path='/domains'>
                          <DomainOrders />
                        </PrivateRoute>

                        <PrivateRoute path='/github/callback'>
                          <GitHubAuthorize />
                        </PrivateRoute>

                        <Route path='/'>
                          {main_state.initialized && (
                            <>
                              {main_state.user.onboarding_step ? (
                                <>
                                  {main_state.user.onboarding_step === 2 ? (
                                    <Redirect to='/createspace/stepone' />
                                  ) : (
                                    // else step 1
                                    <NewUserLanding />
                                  )}
                                </>
                              ) : (
                                // else step 0 -> onboarding completed
                                <Usage />
                              )}
                            </>
                          )}
                        </Route>
                      </Switch>
                    </>
                  )}
                </>
              )}
            </Switch>

            {main_state.loading.length > 0 && main_state.loading[0] !== 'fetchFiles' && (
              <LoadingBox position='fixed' top_displacement='auto' ariaLabelSpinner='Loading content' />
            )}

            <ModalHandler className='modal_button' />

            <NotificationHandler />

            <ConnectivityCheck />

            {/* <IdleCheck /> */}
          </Container>

          {display_footer && (
            <div className='footer-terms'>
              <Terms position='relative' />
            </div>
          )}
        </Router>
      </Suspense>
    </>
  );
};

export default Base;
