import { util, logging, getApiBaseUrl } from 'w3-user-ui-component';
import { SpaceTemplateType } from './InterfaceAndTypeUtil';
import React from 'react';

/**
 * **Bootstrap 4.6** media breakpoints.
 *
 * Note from Bootstrap on these numbers:
 *
 * Note that since browsers do not currently support range context queries,
 * we work around the limitations of min- and max- prefixes and viewports with
 * fractional widths (which can occur under certain conditions on high-dpi devices, for instance)
 *  by using values with higher precision for these comparisons.
 */
export const BREAKPOINTS = {
  xsmall: 575.98,
  small: 767.98,
  medium: 991.98,
  large: 1199.98,
};

/**
 * Set the position of the help launcher when in mobile view
 */
export const setLauncherPosition = (event?: any, isEditor?: boolean) => {
  const launcher = document.getElementById('launcher');
  if (launcher) {
    if (window.location.pathname.indexOf('/domains') > -1) {
      launcher!.style.bottom = '0px';
    } else {
      if (window.innerWidth < BREAKPOINTS.medium) {
        launcher!.style.bottom = isEditor ? '30px' : '60px';
        //launcher!.style.left = '0px';
      } else {
        launcher!.style.bottom = '0px';
        //launcher!.style.left = 'initial';
      }
    }
  }
};

export const displayDate = (dispDate: string | Date) => {
  let date: Date;
  if (typeof dispDate === 'string') {
    const num = Date.parse(dispDate);
    date = new Date(num);
  } else {
    date = dispDate;
  }

  return date.toLocaleString();
};

export const spaceDomain = () => {
  const env = util.getEnv();

  switch (env) {
    case 'develop':
      return 'w3sdevelop.com';

    case 'staging':
      return 'w3stages.com';

    case 'prod':
    default:
      return 'w3spaces.com';
  }
};

export const baseUrl = () => {
  return `${getApiBaseUrl()}/spaces`;
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Returns a Promise that resolves after "ms" Milliseconds
export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const redirectToSignupFromCreate = (selectedTemplate: SpaceTemplateType) => {
  const currentUrlEncoded = encodeURIComponent(`${util.getSpacesUrl()}/createspace?template=${selectedTemplate.template_id}`);

  const redirectUrl = util.getProfileUrl() + `/signup?redirect_url=${currentUrlEncoded}`;

  logging.logDebug('redirectToSignupFromCreate -> redirectUrl: ', redirectUrl);

  if (localStorage.getItem('skipRedirect') === 'true') {
    return;
  }

  window.location.href = redirectUrl;
};

/**
 * Check if enter or numpad enter is pressed
 * @param event Keyboard event object
 * @returns
 */
export const isEnterKeyPressed = (event: React.KeyboardEvent) => {
  return event.code === 'Enter' || event.code === 'NumpadEnter';
};

/**
 * Check if Enter, Numpad enter or spacebar is pressed
 * @param event Keyboard event object
 * @returns
 */
export const isActivationKeyPressed = (event: React.KeyboardEvent) => {
  return isEnterKeyPressed(event) || event.code === 'Space';
};
