import {
    ErrorType,
    typedAction,
    UserNotificationType,
    UserNotificationDismissType,
    UserNotificationUpdateType,
} from '../../Util/InterfaceAndTypeUtil';
import { uuidv4 } from '../../Util/MixedUtil';
// import * as DataUtil from '../../Util/DataUtil';
import { logging } from 'w3-user-ui-component';

interface NotificationReducerStateType {
    notifications: UserNotificationType[],
}

export const notificationInitialState: NotificationReducerStateType = {
    notifications: [],
}

export const addNotificationError = (error: ErrorType) => {
    return typedAction('Notification::addError', error);
}

export const dismissNotification = (notification: UserNotificationDismissType) => {
    return typedAction('Notification::dismiss', notification);
}

export const updateNotification = (notification: UserNotificationUpdateType) => {
    return typedAction('Notification::update', notification);
}

export const addNotification = (notification: UserNotificationType) => {
    return typedAction('Notification::add', notification);
}

export const upsertNotification = (notification: UserNotificationType) => {
    return typedAction('Notification::upsert', notification);
}


const notificationFromError = (error: ErrorType): UserNotificationType => {
    return {
        id: uuidv4(),
        dismissed: false,
        type: 'error',
        header: 'Error',
        body: `${error.description}`
    };
}

type NotificationAction = ReturnType<
    typeof upsertNotification |
    typeof addNotification |
    typeof dismissNotification |
    typeof updateNotification |
    typeof addNotificationError
>

export function notificationReducer(
    state = notificationInitialState,
    action: NotificationAction,
): NotificationReducerStateType {
    switch (action.type) {
        case 'Notification::dismiss':
            logging.logDebug('notificationReducer -> Notification::dismiss -> action.payload: ', action.payload);

            return {
                ...state,
                notifications: state.notifications.map((n: UserNotificationType) => {
                    if (n.id === action.payload.id) {
                        n.dismissed = true; // TODO: (mid) check with Frode the reason behind setting a flag instead of removing

                        if (typeof n.onClose !== 'undefined') {
                          n.onClose(n.id);
                        }
                    }

                    return n;
                })
            };

        case 'Notification::update':
            logging.logDebug('notificationReducer -> Notification::update -> action.payload: ', action.payload);

            return {
                ...state,
                notifications: state.notifications.map((n: UserNotificationType) => {
                    if (n.id === action.payload.id) {
                        // return DataUtil.getWithDefaults(action.payload, n, true) as UserNotificationType
                        return { ...n, ...action.payload } as UserNotificationType
                    }

                    return n
                })
            };

          case 'Notification::upsert':
            logging.logDebug('notificationReducer -> Notification::upsert -> action.payload: ', action.payload);

            let notificationUpdated = false;

            const notifications = state.notifications.map((n: UserNotificationType) => {
              if (n.id === action.payload.id) {
                notificationUpdated = true;

                return { ...n, ...action.payload } as UserNotificationType;
              }

              return n;
            });

            if (notificationUpdated) {
              return {
                ...state,
                notifications,
              };
            } else {
              return {
                ...state,
                notifications: [...state.notifications, action.payload]
              };
            }
  
        case 'Notification::add':
            logging.logDebug('notificationReducer -> Notification::add -> action.payload: ', action.payload);

            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            };

        case 'Notification::addError':
            logging.logDebug('notificationReducer -> Notification::addError -> action.payload: ', action.payload);

            return {
                ...state,
                notifications: [...state.notifications, notificationFromError(action.payload)]
            };

        default: return state;
    }
}
