import React from 'react';
import { StarsIcon } from 'w3-user-ui-component';
import { IDefault } from 'src/Util/InterfaceAndTypeUtil';

import styles from './MobileBottomPanelElement.module.scss';

interface IMobileBottomPanelElement extends IDefault {
  selected: boolean;
  disabled?: boolean;
  displayStarsIcon?: boolean;
  ariaLabel?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const MobileBottomPanelElement = ({
  ariaLabel,
  displayStarsIcon = false,
  disabled,
  selected,
  id,
  className,
  onClick,
  children,
}: React.PropsWithChildren<IMobileBottomPanelElement>) => {
  const handleOnClick = (event: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  return (
    <div
      id={id}
      className={`${styles.wrapper}${selected ? ` ${styles.selected}` : ''}${disabled ? ` ${styles.disabled}` : ''}${
        className ? ` ${className}` : ''
      }`}
      onClick={handleOnClick}
      tabIndex={onClick && !disabled ? 0 : -1}
      aria-label={ariaLabel}
      aria-pressed={selected}
      aria-disabled={disabled}
    >
      {children}
      {displayStarsIcon && <StarsIcon className={styles.stars_icon} />}
    </div>
  );
};

export default MobileBottomPanelElement;
