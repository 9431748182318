// prs - parameters / properties / options / arguments
// TODO: (low) convert to typescript? https://dev.to/bytebodger/default-props-in-react-typescript-2o5o

export const getPrmType = (prm) => {
    let prmType = Object.prototype.toString.call(prm);

    prmType = prmType.substring(8);

    const output = prmType.substring(0, prmType.length - 1);

    // console.log('DataUtil -> getPrmType -> output: ', output);

    return output;
}

export const setDefaults = (prs, defaultPrs, recursively) => {
    if (typeof recursively === 'undefined') {
        recursively = true;
    }

    let key,
        prmType;

    if (recursively) {
        let prmIsArray = false,
            prmIsObj = false;

        for (key in defaultPrs) {
            if (defaultPrs.hasOwnProperty(key)) {
                prmType = getPrmType(defaultPrs[key]);

                if (prmType === 'Function' || prmType === 'Symbol') {
                    continue;
                }

                prmIsObj = prmType === 'Object';

                prmIsArray = !prmIsObj && prmType === 'Array';

                if (prs.hasOwnProperty(key)) {
                    if (prmIsObj || prmIsArray) {
                        setDefaults(prs[key], defaultPrs[key], true);
                    }
                } else {
                    if (prmIsObj) {
                        prs[key] = {};
                    } else if (prmIsArray) {
                        prs[key] = [];
                    }

                    if (prmIsObj || prmIsArray) {
                        setDefaults(prs[key], defaultPrs[key], true);
                    } else {
                        // try {
                        prs[key] = defaultPrs[key];
                        // } catch (exc) {
                        //   console.error(exc)
                        //   console.log('prs: ', JSON.stringify(prs))
                        //   console.log('key: ', key)
                        //   console.log('defaultPrs: ', JSON.stringify(defaultPrs))
                        // }
                    }
                }
            }
        }
    } else {
        const allowedPrmTypes = {
            'Boolean': true,
            'String': true,
            'Number': true
        }

        for (key in defaultPrs) {
            if (defaultPrs.hasOwnProperty(key)) {
                if (!prs.hasOwnProperty(key)) {
                    prmType = getPrmType(defaultPrs[key]);

                    if (allowedPrmTypes[prmType] !== true) {
                        continue;
                    }

                    prs[key] = defaultPrs[key];
                }
            }
        }
    }
}

export const getACopy = (prs) => {
    let output = {},
        key,
        prmType;

    const allowedPrmTypes = {
        'Boolean': true,
        'String': true,
        'Number': true
    }

    for (key in prs) {
        if (prs.hasOwnProperty(key)) {
            prmType = getPrmType(prs[key]);

            if (allowedPrmTypes[prmType] !== true) {
                continue;
            }

            output[key] = prs[key];
        }
    }

    return output;
}

export const getADeepCopy = (prs, output = {}) => {
    let key,
        prsType = '';

    for (key in prs) {
        if (prs.hasOwnProperty(key)) {
            prsType = getPrmType(prs[key]);

            if (prsType === 'Object') {
                output[key] = getADeepCopy(prs[key], {});
            } else if (prsType === 'Array') {
                output[key] = getADeepCopy(prs[key], []);
            } else if (prsType === 'Function' || prsType === 'Symbol') {
                continue;
            } else {
                output[key] = prs[key];
            }
        }
    }

    return output;
}

export const getWithDefaults = (prs, defaultPrs, recursively) => {
    if (typeof recursively === 'undefined') {
        recursively = true;
    }

    let output;

    if (recursively) {
        output = getADeepCopy(prs);
    } else {
        output = getACopy(prs);
    }

    setDefaults(output, defaultPrs, recursively);

    return output;
}

export const getMerged = (prs1, prs2, recursively) => {
    if (typeof recursively === 'undefined') {
        recursively = true;
    }

    let output;

    if (recursively) {
        output = getADeepCopy(prs2);
    } else {
        output = getACopy(prs2);
    }

    setDefaults(output, prs1, recursively);

    return output;
}

export const checkSet = (prs, key, value) => {
    if (!prs.hasOwnProperty(key)) {
        prs[key] = value;
    }
}

export const getLeftDifference = (prs1, prs2) => {
    // if (typeof recursively === 'undefined') {
    //     recursively = true;
    // }

    let output = {},
        key,
        prmType,
        prmIsObj,
        prmIsArray;

    const allowedPrmTypes = {
        'Boolean': true,
        'String': true,
        'Number': true,
        'Array': true,
        'Object': true
    }

    for (key in prs1) {
        if (prs1.hasOwnProperty(key)) {
            prmType = getPrmType(prs1[key]);

            if (allowedPrmTypes[prmType] !== true) {
                continue;
            }

            prmIsObj = prmType === 'Object';

            prmIsArray = !prmIsObj && prmType === 'Array';

            if (prs2.hasOwnProperty(key)) {
                // not tested how it will behave on inner objects or arrays
                if (prs1[key] !== prs2[key]) {
                    if (prmIsObj || prmIsArray) {
                        output[key] = getADeepCopy(prs2[key]);
                    } else {
                        output[key] = prs2[key];
                    }
                }
            }
            // else {
            //     if (prmIsObj || prmIsArray) {
            //         output[key] = getADeepCopy(prs1[key]);
            //     } else {
            //         output[key] = prs1[key];
            //     }
            // }
        }
    }

    return output;
}

export const getDifference = (prs1, prs2) => {
    // TODO: (mid) optimize
    let diff = getLeftDifference(prs1, prs2);
    let inversedDiff = getLeftDifference(prs2, prs1);

    return getWithDefaults(diff, inversedDiff);
}

export const isEmpty = (prs) => {
    let key;

    for (key in prs) {
        if (prs.hasOwnProperty(key)) {
            return false;
        }
    }

    return true;
}

export const getAltDataCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};
