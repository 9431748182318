import { fetchSpaces, logging } from 'w3-user-ui-component';
import { FileType, SpaceType, SpaceVariant, SpaceVariantType, SubscriptionPlanType } from './InterfaceAndTypeUtil';
import { getCurrentUts } from './TimeUtil';
import { sleep } from './DelayUtil';
import { IOperationResult } from 'w3-user-ui-component/dist/Util/InterfaceAndTypeUtil';

export const compareMostRecentlyUpdatedSpaces = (spaceA: SpaceType, spaceB: SpaceType) => {
  // logging.logDebug('SpaceUtil -> compareMostRecentlyUpdatedSpaces -> spaceA: ', spaceA)
  // logging.logDebug('SpaceUtil -> compareMostRecentlyUpdatedSpaces -> spaceB: ', spaceB)

  const mostRecentA = getMostRecentUpdate(spaceA.files) ?? new Date(0);
  const mostRecentB = getMostRecentUpdate(spaceB.files) ?? new Date(0);

  return mostRecentB?.getTime() - mostRecentA?.getTime();
};

export const compareMostRecentlyUpdatedFiles = (fileA: FileType, fileB: FileType) => {
  // logging.logDebug('SpaceUtil -> compareMostRecentlyUpdatedFiles -> fileA: ', fileA)
  // logging.logDebug('SpaceUtil -> compareMostRecentlyUpdatedFiles -> fileB: ', fileB)

  const lastModA = fileA.last_modified ?? new Date(0);
  const lastModB = fileB.last_modified ?? new Date(0);

  return lastModB.getTime() - lastModA.getTime();
};

const getMostRecentUpdate = (files: FileType[] | undefined) => {
  if (files === undefined || files.length === 0) {
    return new Date(0);
  }

  return files
    .map((file: FileType) => file.last_modified)
    .reduce((acc: Date | undefined, current: Date | undefined) => {
      if (!acc) {
        return current;
      }

      if (!current && acc) {
        return acc;
      }

      if (current && acc) {
        return acc >= current ? acc : current;
      }

      return new Date(0);
    });
};

export const getStorageBytesUsedBySpace = (space: SpaceType): number => {
  if (space.files === undefined) {
    return 0;
  }
  const storageUsed = space.files.map((file: FileType) => file.size).reduce((a, b) => a + b, 0);

  return storageUsed ?? 0;
};

export const userOwnsSpace = (spaces: SpaceType[], space_id: string): boolean => {
  const filtered_spaces = spaces.filter((space: SpaceType) => space.id === space_id);

  if (filtered_spaces.length) {
    return true;
  }

  return false;
};

/**
 * Get which variant of spaces this is
 * @param space
 * @returns
 */
export const getSpaceVariantType = (space: SpaceType | undefined): SpaceVariantType => {
  if (isDynamicSpace(space)) {
    const template = space?.templateId || '';
    const split = template.split('-');

    if (split.length < 2) {
      return SpaceVariantType.NONE;
    }

    return split[1] as SpaceVariantType;
  }

  return SpaceVariantType.HTML;
};

/**
 * Which technology is the space based on.
 * Possible values are SpaceVariantType.HTML, SpaceVariantType.NODE, SpaceVariantType.PHP, SpaceVariantType.PYTHON
 * @param space
 * @returns
 */
export const getSpaceTechnology = (space: SpaceType | undefined): SpaceVariantType => {
  if (isDynamicSpace(space)) {
    const template = space?.templateId || space?.dynamicMeta?.image || '';
    const split = template.split('-');

    if (split.length < 2) {
      return SpaceVariantType.NONE;
    }

    return split[0] as SpaceVariantType;
  }

  return SpaceVariantType.HTML;
};

/**
 * Is the space dynamic (not html).
 * @param space
 * @returns
 */
export const isDynamicSpace = (space: SpaceType | undefined): boolean => {
  return !!space && !!space.type && space.type === SpaceVariant.DYNAMIC;
};

/**
 * Do we have a subscriptin that enables fullstack
 * @param subscriptionPlan
 * @returns True if fullstack is enabled
 */
export const fullStackEnabled = (subscriptionPlan: SubscriptionPlanType) => {
  return !(subscriptionPlan.name === 'free' || subscriptionPlan.name === 'pro');
};

/**
 * Enable/disable features
 */
export const FeatureFlags = {
  /**
   * Should ai be enabled in editor
   */
  ai: true, // getEnv() !== 'prod',
  /**
   * Any subscriptions the ai functionality should be hidden
   */
  aiHiddenOnSubscription: ['none'],
  /**
   * True if estimation of ai questions to be enabled
   */
  aiEstimateCost: true,
  /**
   * Enable top banner with internal ads
   */
  topBanner: false,
};

/**
 * Check if subscription is in the list of subscriptions that AI is to be turned off for.
 * Set FeatureFlags.ai to false on those subscriptions.
 * @param subscription Subscription name
 */
export const checkAIAgainstSubscription = (subscription: string) => {
  if (FeatureFlags.ai && FeatureFlags.aiHiddenOnSubscription.findIndex((sub: string) => sub === subscription.toLowerCase()) > -1) {
    FeatureFlags.ai = false;
  }
};
//create a login page using html, css and javascript

export const dynamicSpaceCanHibernate = (space: SpaceType): boolean => {
  return ['BANNED', 'DELETED'].includes(space.state) === false && typeof space.dynamicHibernateMode !== 'undefined' && space.dynamicHibernateMode === 'IDLE';
};

export const dynamicSpaceIsHibernated = (space: SpaceType): boolean => {
  if (!isDynamicSpace(space)) {
    return false;
  }

  if (!dynamicSpaceCanHibernate(space)) {
    return false;
  }

  return space.dynamicMeta?.status === 'STOPPED';
};

export const unhibernateDynamicSpace = async ({
  spaceId,
  timeout = 120,
}: {
  spaceId: string,
  timeout?: number, // seconds
}): Promise<IOperationResult<any>> => {
  const triggerWakeUpRes = await fetchSpaces<any>({
    url: `/dynamic-space/keep-awake/${spaceId}`,
    method: 'GET',
  });

  if (triggerWakeUpRes.error.code !== '0') {
    logging.logError('SpaceUtil -> unhibernateDynamicSpace -> error -> triggerWakeUpRes: ', triggerWakeUpRes);
    return triggerWakeUpRes;
  }

  const timeoutUts = getCurrentUts() + timeout;

  let reachedTimeout = false;

  let wokenUp = false;

  let hibernationStatus: number | null = null;

  await sleep(10000); // sleep for 10 seconds

  while (!reachedTimeout && !wokenUp) {
    const hibernationStatusRes = await fetchSpaces<string>({
      url: `/dynamic-space/hibernation-status/${spaceId}`,
      method: 'GET',
      authenticated: false,
    });

    if (hibernationStatusRes.error.code !== '0') {
      logging.logError('SpaceUtil -> unhibernateDynamicSpace -> error -> hibernationStatusRes: ', hibernationStatusRes);
      return hibernationStatusRes;
    }

    hibernationStatus = parseInt(hibernationStatusRes.data);

    if (hibernationStatus === -1) {
      const output: IOperationResult<any> = {
        error: {
          code: 'INVALID_HIBERNATION_STATUS',
          description: 'Invalid hibernation status',
        },
        data: {
          timeoutUts,
          wokenUp,
          hibernationStatus,
        },
      };

      logging.logError('SpaceUtil -> unhibernateDynamicSpace -> error -> output: ', output);
      return output;
    }

    if (hibernationStatus === 1) {
      wokenUp = true;
      break;
    }

    if (getCurrentUts() > timeoutUts) {
      reachedTimeout = true;
      break;
    }

    await sleep(5000); // sleep for 5 seconds
  }

  if (reachedTimeout) {
    const output: IOperationResult<any> = {
      error: {
        code: 'TIMEOUT',
        description: 'Timeout',
      },
      data: {
        timeoutUts,
        wokenUp,
        hibernationStatus,
      },
    };

    logging.logError('SpaceUtil -> unhibernateDynamicSpace -> error -> output: ', output);
    return output;
  }

  return {
    error: {
      code: '0',
    },
    data: {
      timeoutUts,
      wokenUp,
      hibernationStatus,
    },
  };
};
