import { useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from '../../Redux/Reducer/NotificationReducer'
import { uuidv4 } from '../../Util/MixedUtil'

const Connectivity: FC = () => {

    const dispatch = useDispatch()

    const isOnline = () => {
        dispatch(addNotification({
            id: uuidv4(),
            dismissed: false,
            type: "success",
            header: "Online",
            body: "Connectivity has been re-established!"
        }))
    }

    const isOffline = () => {
        dispatch(addNotification({
            id: uuidv4(),
            dismissed: false,
            type: "error",
            header: "You are offline",
            body: <span>Please check your internet connection and try again. <br /><br /><strong>Note:</strong> None of your work will be saved while you are offline.</span>
        }))
    }

    useEffect(() => {
        window.addEventListener("offline", isOffline)
        window.addEventListener("online", isOnline)
        return () => {
            window.removeEventListener("offline", isOffline)
            window.removeEventListener("online", isOnline)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null

}

export default Connectivity
