import React from 'react';
import './LoadingBox.scss';
import { Spinner } from 'w3-user-ui-component';

interface LoadingBoxPropsType {
  text?: string;
  text_placement?: 'top' | 'bottom';
  position?: 'relative' | 'absolute' | 'fixed';
  top_displacement?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  className?: string;
  ariaLabelSpinner?: string;
}

/**
 * Size of the spinner.
 * Possible values are 'sm' | 'md' | 'lg' | 'xl'
 * @default lg
 */
export const SizesTypes = Spinner.utils.size;

function LoadingBox({
  text = 'Loading...',
  text_placement = 'top',
  position = 'relative',
  top_displacement = '-10%',
  size = SizesTypes.lg,
  children = undefined,
  className = undefined,
  ariaLabelSpinner = undefined,
}: React.PropsWithChildren<LoadingBoxPropsType>) {
  return (
    <div
      className={'loading-box' + (!!className ? ' ' + className : '')}
      style={{
        position,
      }}
    >
      <div className='-inner-wrp'>
        <div
          className='-inner-wrp'
          style={{
            top: top_displacement,
          }}
        >
          {text_placement === 'top' && <div className='-title'>{text}</div>}

          <Spinner className='mx-auto mt-4' size={size} ariaLabel={ariaLabelSpinner} />

          {text_placement === 'bottom' && <div className='-title'>{text}</div>}
          {!!children && children}
        </div>
      </div>
    </div>
  );
}

export default LoadingBox;
