import { combineReducers } from 'redux';
import { mainReducer } from './Reducer/MainReducer';
import { createSpaceReducer } from './Reducer/CreateSpaceReducer';
import { authReducer } from './Reducer/AuthReducer';
import { notificationReducer } from './Reducer/NotificationReducer';
import { modalReducer } from './Reducer/ModalReducer';
import { domainsReducer } from './Reducer/DomainsReducer';
import { spacePreviewSessionReducer } from './Reducer/SpacePreviewSessionReducer';

export const rootReducer = combineReducers({
    main: mainReducer,
    createSpace: createSpaceReducer,
    auth: authReducer,
    notification: notificationReducer,
    modal: modalReducer,
    domains: domainsReducer,
    spacePreviewSession: spacePreviewSessionReducer,
});

export type RootStateType = ReturnType<typeof rootReducer>;
