import { AnyAction, createAction } from '@reduxjs/toolkit';
import { logging } from 'w3-user-ui-component';
import { DomainOrderType, DomainProductMapType } from '../../Util/InterfaceAndTypeUtil';


interface DomainsReducerStateType {
    orders: DomainOrderType[],
    products: DomainProductMapType
}

const domainsReducerInitialState: DomainsReducerStateType = {
    orders: [],
    products: {},
}

export const setDomainsOrdersAction = createAction<DomainOrderType[]>('Domains::setDomainOrders');
export const setDomainProductsAction = createAction<DomainProductMapType>('Domains::setDomainProducts');

export function domainsReducer(
    state = domainsReducerInitialState,
    action: AnyAction,
): DomainsReducerStateType {
    if (setDomainsOrdersAction.match(action)) {
        logging.logDebug('domainsReducer -> Domains::setDomainOrders -> action.payload: ', action.payload);

        return {
            ...state,
            orders: action.payload
        };
    }

    if (setDomainProductsAction.match(action)) {
        logging.logDebug('domainsReducer -> Domains::setDomainProducts -> action.payload: ', action.payload);

        return {
            ...state,
            products: action.payload
        };
    }

    return state;
}