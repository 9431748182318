import { typedAction } from '../../Util/InterfaceAndTypeUtil';
import { logging } from 'w3-user-ui-component';

export interface ISpacePreviewSession {
  id: string, // session id
  rawExpiryUts: number, // closer to the real/server side expiry time
  expiryUts: number, // rawExpiryUts - 5 mins
  baseUrl: string,
};

interface ISpacePreviewSessionState {
  [spaceId: string]: ISpacePreviewSession,
};

export const spacePreviewSessionInitialState: ISpacePreviewSessionState = {};

export const upsertSpacePreviewSession = (spaceId: string, spacePreviewSession: ISpacePreviewSession) => {
  return typedAction('SpacePreviewSession::upsert', [spaceId, spacePreviewSession]);
}

type SpacePreviewSessionActionType = ReturnType<typeof upsertSpacePreviewSession>;

export function spacePreviewSessionReducer(
  state = spacePreviewSessionInitialState,
  action: SpacePreviewSessionActionType,
): ISpacePreviewSessionState {
  switch (action.type) {
    case 'SpacePreviewSession::upsert':
      logging.logDebug('spacePreviewSessionReducer -> SpacePreviewSession::upsert -> action.payload: ', action.payload);

      const spaceId = action.payload[0] as string;

      const spacePreviewSession = action.payload[1] as ISpacePreviewSession;

      // return {
      //   ...state,
      //   spaceId: spacePreviewSession
      // };

      state[spaceId] = spacePreviewSession;

      return state;

    default:
      return state;
  }
}
