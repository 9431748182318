import React, { useEffect, useState } from 'react';
import { Check, Cpu, Folder, JournalText, TerminalX } from 'react-bootstrap-icons';
import { ReactComponent as PencilIcon } from '../../../Asset/Img/Icon/pencil.svg';
import { IDefault } from 'src/Util/InterfaceAndTypeUtil';
import MobileBottomPanelElement from '../../MobileBottomPanel/MobileBottomPanelElement/MobileBottomPanelElement';
import MobileBottomPanel from '../../MobileBottomPanel/MobileBottomPanel';
import MobileMenuChild from 'src/Component/MobileBottomPanel/MobileMenuChild/MobileMenuChild';

import styles from './MobileEditorPanel.module.scss';
import { FeatureFlags } from 'src/Util/SpaceUtil';
import AiIcon from 'src/Asset/Img/AiIcon';

export enum MobileViewItem {
  CodeEditor = 'code_editor',
  Files = 'files',
  Terminal = 'terminal',
  Status = 'status',
  Log = 'log',
  Admin = 'admin',
  Ai = 'ai',
}

interface IMobileEditorPanel extends IDefault {
  disabled?: boolean;
  activeItem?: MobileViewItem;
  isDynamicSpace?: boolean;
  terminalIsConnected?: boolean;
  logsIsConnected?: boolean;
  onClickItem: (item: MobileViewItem) => void;
}

const MobileEditorPanel = ({
  disabled = false,
  activeItem = MobileViewItem.CodeEditor,
  onClickItem,
  isDynamicSpace = false,
  terminalIsConnected = false,
  logsIsConnected = false,
  id,
  className,
}: IMobileEditorPanel) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEditorSelected, setIsEditorSelected] = useState(false);

  useEffect(() => {
    if (
      activeItem === MobileViewItem.CodeEditor ||
      activeItem === MobileViewItem.Terminal ||
      activeItem === MobileViewItem.Log ||
      activeItem === MobileViewItem.Status
    ) {
      setIsEditorSelected(true);
    } else {
      setIsEditorSelected(false);
    }
  }, [activeItem]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <MobileBottomPanel
      id={id}
      className={`${styles.wrapper}${className ? ` ${className}` : ''}`}
      disabled={isDisabled}
      displayMenu
      menuChildren={
        <>
          {/* FeatureFlags.ai && (
            <MobileMenuChild
              className={styles.menuChild}
              disabled={isDisabled}
              onClick={() => onClickItem(MobileViewItem.Ai)}
              role='menuitemcheckbox'
              ariaLabel='kAI'
              ariaChecked={activeItem === MobileViewItem.Ai}
            >
              {activeItem === MobileViewItem.Ai && <Check className={styles.checkIcon} />}
              <AiIcon />
              <div>kAI</div>
            </MobileMenuChild>
          ) */}

          {isDynamicSpace && (
            <MobileMenuChild
              className={styles.menuChild}
              disabled={isDisabled}
              onClick={() => onClickItem(MobileViewItem.Terminal)}
              ariaLabel='Terminal'
              role='menuitemcheckbox'
              ariaChecked={activeItem === MobileViewItem.Terminal}
            >
              {activeItem === MobileViewItem.Terminal && <Check className={styles.checkIcon} />}
              <TerminalX className={terminalIsConnected ? styles.color_green : ''} />
              <div>Terminal</div>
            </MobileMenuChild>
          )}

          {isDynamicSpace && (
            <MobileMenuChild
              className={styles.menuChild}
              disabled={isDisabled}
              onClick={() => onClickItem(MobileViewItem.Log)}
              ariaLabel='Log'
              role='menuitemcheckbox'
              ariaChecked={activeItem === MobileViewItem.Log}
            >
              {activeItem === MobileViewItem.Log && <Check className={styles.checkIcon} />}
              <JournalText />
              {logsIsConnected && (
                <svg
                  className={`${styles.display_log_dot}${activeItem !== MobileViewItem.Log ? ` ${styles.not_selected}` : ''}`}
                  viewBox='0 0 64 64'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='12' cy='12' r='12' />
                </svg>
              )}
              Log
            </MobileMenuChild>
          )}

          <MobileMenuChild
            className={styles.menuChild}
            disabled={isDisabled}
            onClick={() => onClickItem(MobileViewItem.Status)}
            ariaLabel='Status'
            role='menuitemcheckbox'
            ariaChecked={activeItem === MobileViewItem.Status}
          >
            {activeItem === MobileViewItem.Status && <Check className={styles.checkIcon} />}
            <Cpu />
            Status
          </MobileMenuChild>
        </>
      }
    >
      <MobileBottomPanelElement
        selected={isEditorSelected}
        disabled={isDisabled}
        onClick={() => onClickItem(MobileViewItem.CodeEditor)}
        ariaLabel='Editor'
      >
        <PencilIcon /> Editor
      </MobileBottomPanelElement>

      <MobileBottomPanelElement
        selected={activeItem === MobileViewItem.Files}
        disabled={isDisabled}
        onClick={() => onClickItem(MobileViewItem.Files)}
        ariaLabel='Files'
      >
        <Folder /> Files
      </MobileBottomPanelElement>

      {FeatureFlags.ai && (
        <MobileBottomPanelElement
          selected={activeItem === MobileViewItem.Ai}
          disabled={isDisabled}
          onClick={() => onClickItem(MobileViewItem.Ai)}
          ariaLabel='kAI'
        >
          <AiIcon /> kAI
        </MobileBottomPanelElement>
      )}
    </MobileBottomPanel>
  );
};

export default MobileEditorPanel;
