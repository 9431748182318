import { Dispatch } from 'redux';
import { auth, logging } from 'w3-user-ui-component';
import { setLoggedIn } from '../Reducer/AuthReducer';

export function refreshAuthStatus(context: string, notRedirect?: boolean) {
  return async (dispatch: Dispatch) => {
    const res = await auth.processUserSession({
      context: `AuthAction -> refreshAuthStatus -> context: ${context}`,
      preferDethrottleCache: false,
      handleRedirectionLogic: !notRedirect,
    });
    logging.logDebug('AuthAction -> refreshAuthStatus -> processUserSession -> res: ', res);

    if (res.error.code === '0') {
      dispatch(setLoggedIn(true));
    } else {
      if (!notRedirect) {
        auth.restartUserSessionViaRedirect({
          context: 'refreshAuthStatus',
          originUrl: window.location.href,
          reason: res,
        });
      }

      dispatch(setLoggedIn(false));
    }
  };
}
