import { Link, useHistory } from 'react-router-dom';
import { ButtonV2 } from 'w3-user-ui-component';
import BackArrow from 'src/Asset/Img/BackArrow';

import './BackArrowButton.scss';

interface BackArrowButtonProps {
  text: string;
  to?: string;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  handleOnClick?: (event: any, history: any) => void;
}

function BackArrowButton(props: BackArrowButtonProps) {
  const history = useHistory();

  function handleClick(event: any) {
    if (!!props.handleOnClick && !props.disabled) {
      props.handleOnClick(event, history);
    }
  }

  return !!props.handleOnClick && !props.to ? (
    <ButtonV2
      type='noframe'
      text={props.text}
      disabled={props.disabled}
      onClick={handleClick}
      positionIcon='left'
      icon={<BackArrow size={16} />}
      textClassName='back-button-text'
      className={`back-button${props.className ? ` ${props.className}` : ''}`}
      ariaLabel={props.ariaLabel || 'Back'}
    />
  ) : props.disabled ? (
    <ButtonV2
      type='noframe'
      text={props.text}
      disabled={props.disabled}
      positionIcon='left'
      icon={<BackArrow size={16} />}
      textClassName='back-button-text'
      className={`back-button${props.className ? ` ${props.className}` : ''}`}
      ariaLabel={props.ariaLabel || 'Back'}
    />
  ) : (
    <Link to={props.to!}>
      <ButtonV2
        type='noframe'
        text={props.text}
        disabled={props.disabled}
        onClick={handleClick}
        positionIcon='left'
        icon={<BackArrow size={16} />}
        textClassName='back-button-text'
        className={`back-button${props.className ? ` ${props.className}` : ''}`}
        tabIndex={-1}
        ariaLabel={props.ariaLabel || 'Back'}
      />
    </Link>
  );
}

export default BackArrowButton;
