import { useSelector } from 'react-redux';
import { RootStateType } from '../../Redux';
import './QuotaInfoBar.scss';
import { InfoBar, util } from 'w3-user-ui-component';
import * as SubscriptionPlanUtil from '../../Util/SubscriptionPlanUtil';
import * as DataSizeUtil from '../../Util/DataSizeUtil';

function QuotaInfoBar() {
  const featureFlags = useSelector((state: RootStateType) => state.main.featureFlags);
  const subscription_plan = useSelector((state: RootStateType) => state.main.subscription_plan);
  const subscription_plan_options = useSelector((state: RootStateType) => state.main.subscription_plan_options);
  const subscription_plan_time_span_end_dts = SubscriptionPlanUtil.getSubscriptionPlanTimeSpanEndDts(subscription_plan, 'MMM DD, YYYY');
  const user = useSelector((state: RootStateType) => state.main.user);
  const usage = useSelector((state: RootStateType) => state.main.usage);
  const usage_status = useSelector((state: RootStateType) => state.main.usage_status);
  const quota = useSelector((state: RootStateType) => state.main.quota);
  const upgrade_url = util.getUpgradeLink();
  const cname = SubscriptionPlanUtil.getSubscriptionPlanTitle(subscription_plan.name, subscription_plan.frequency, subscription_plan_options);

  const learn_more_url = upgrade_url;

  return (
    <div className='quota-info-bar-container'>
      {usage_status.trial.time_elapsed === 'out_of_quota' && (
        <InfoBar>
          <span>
            {user.trial.type === 'none' ? (
              <>You have downgraded to a Spaces free plan. By upgrading your plan you will be able to start using Spaces again.</>
            ) : (
              <>
                Your {cname} plan included {quota.trial.type === 'extended' ? 'two months' : 'a free month'} trial period. By upgrading your plan you
                will be able to start using Spaces again.
              </>
            )}
          </span>
        </InfoBar>
      )}

      {usage_status.web_traffic.number_of_requests === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a{' '}
            <strong>
              {SubscriptionPlanUtil.getSubscriptionPlanTitle(subscription_plan.name, subscription_plan.frequency, subscription_plan_options)}
            </strong>{' '}
            plan you get {quota.web_traffic.number_of_requests} requests per month. Since you have reached this limit, we have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Your {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'}{' '}
            will be opened again when your next subscription period starts, <strong>{subscription_plan_time_span_end_dts}</strong>. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                  subscription_plan.can_be_upgraded ? (
                    <>
                      To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                    </>
                  ) : (
                    <a href={learn_more_url}>Learn more</a>
                  )
              )
            }
           
          </span>
        </InfoBar>
      )}

      {usage_status.web_traffic.data_served === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you get {DataSizeUtil.getHumanReadableSizeFromKiloBytes(quota.web_traffic.data_served)} data per
            month. Since you have reached this limit, we have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Your {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'}{' '}
            will be opened again when your next subscription period starts, <strong>{subscription_plan_time_span_end_dts}</strong>. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.amount === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you get {DataSizeUtil.getHumanReadableSizeFromKiloBytes(quota.storage.amount)} of storage. Since you
            have used {DataSizeUtil.getHumanReadableSizeFromKiloBytes(usage.storage.amount)}, which is more storage than included in your plan, we
            have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Please delete some files to get below the limit, and your{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'} will be reopened
            automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage.storage.number_of_dynamic_spaces > 0 && usage_status.dynamic.storage_amount === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you get {DataSizeUtil.getHumanReadableSizeFromKiloBytes(quota.dynamic.storage_amount)} of storage. Since you
            have used {DataSizeUtil.getHumanReadableSizeFromKiloBytes(usage.dynamic.storage_amount)}, which is more storage than included in your plan, we
            have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Please delete some files to get below the limit, and your{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'} will be reopened
            automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.number_of_spaces === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you can create {quota.storage.number_of_spaces} static{' '}
            {Number(quota.storage.number_of_spaces) === 1 ? 'space' : 'spaces'}. Since you have {usage.storage.number_of_spaces}{' '}
            {Number(usage.storage.number_of_spaces) === 1 ? 'static space' : 'static spaces'}, which is more than included in your{' '}
            plan, we have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for{' '}
            public access. Please delete those spaces you do not want to keep to get below the limit, and{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'the last one' : 'the rest of your spaces'}{' '}
            will be reopened automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.number_of_dynamic_spaces === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you can create {quota.storage.number_of_dynamic_spaces} dynamic{' '}
            {Number(quota.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'}. Since you have {usage.storage.number_of_dynamic_spaces}{' '}
            {Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'dynamic space' : 'dynamic spaces'}, which is more than included in your{' '}
            plan, we have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for{' '}
            public access. Please delete those spaces you do not want to keep to get below the limit, and{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'the last one' : 'the rest of your spaces'}{' '}
            will be reopened automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.max_file_size === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan you can have a max file size of{' '}
            {DataSizeUtil.getHumanReadableSizeFromKiloBytes(quota.storage.max_file_size)}. Since you have a file of{' '}
            {DataSizeUtil.getHumanReadableSizeFromKiloBytes(usage.storage.max_file_size)}, which is bigger than the max size included in your plan, we
            have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Please delete or edit them to get below the limit, and your{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'} will be reopened
            automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.number_of_files_per_space === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan a space can hold up to {quota.storage.number_of_files_per_space} files. Since you have in one of your
            spaces a total of {usage.storage.number_of_files_per_space} files, which is more than included in your plan, we have temporarily closed{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'} for
            public access. Please delete those files you do not want to keep to get below the limit, and your{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'} will be reopened
            automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}

      {usage_status.storage.number_of_folders_per_space === 'out_of_quota' && (
        <InfoBar>
          <span>
            With a <strong>{cname}</strong> plan a space can hold up to {quota.storage.number_of_folders_per_space} folders. Since you have in one of
            your spaces a total of {usage.storage.number_of_folders_per_space} folders, which is more than included in your plan, we have temporarily
            closed {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'your space' : 'all your spaces'}{' '}
            for public access. Please delete those folders you do not want to keep to get below the limit, and your{' '}
            {Number(usage.storage.number_of_spaces) + Number(usage.storage.number_of_dynamic_spaces) === 1 ? 'space' : 'spaces'} will be reopened
            automatically. &nbsp;
            {
              featureFlags.noUpsell ? <></> : (
                subscription_plan.can_be_upgraded ? (
                  <>
                    To get more requests, <a href={upgrade_url}>upgrade your plan</a>.
                  </>
                ) : (
                  <a href={learn_more_url}>Learn more</a>
                )
              )
            }
          </span>
        </InfoBar>
      )}
    </div>
  );
}

export default QuotaInfoBar;
